/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { Formik, Field, Form as FormikForm /* ErrorMessage */ } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

// import * as Yup from 'yup';

import { FaCheckCircle, FaArrowRight } from "react-icons/fa";

import MaskedInput from "react-text-mask";

import { connect } from "react-redux";
import { private_postApi } from "../../helper/request.helper";

import { submit_form_extra_data } from "../../store/actions";

const Masks = {
  name: [/\w+/],
  telefone: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  cpf: [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],
  cep: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
  agencia: [/\d/, /\d/, /\d/, /\d/],
};

const Form = ({ dados, dispatch, partner_data }) =>
  partner_data !== null && (
    <>
      <ToastContainer autoclose={8000} />
      <Formik
        initialValues={{ ...dados.cliente }}
        onSubmit={async (values, { setSubmitting }) => {
          delete values.emailVerificado;
          delete values.perfil_verificado;
          delete values.exibir_imagem;
          delete values.data_nascimento;
          delete values.foto;
          delete values.sexo;
          delete values.status_perfil;
          delete values.politicamente_exposta;
          private_postApi("/clientes/perfil", values)
            .then(({ data }) => {
              if (data.success || data.msg === "Nada a alterar.") {
                setSubmitting(false);
                toast.success(data.msg);
                return setTimeout(
                  () => dispatch(submit_form_extra_data(values)),
                  3000
                );
              }
              return toast.error(data.msg);
            })
            .catch((err) => {
              toast.error("Opss... Ocorreu um erro, tente novamente");
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="nome">Primeiro nome</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  maxLength="50"
                  name="nome"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nome}
                />
                {errors.nome && touched.nome}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="sobrenome">Sobrenome</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  maxLength="50"
                  name="sobrenome"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sobrenome}
                />
                {errors.sobrenome && touched.sobrenome}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="nome">CPF</label>
                <Field name="cpf">
                  {({ field }) => (
                    <MaskedInput
                      mask={Masks.cpf}
                      {...field}
                      className="form-control"
                      required
                      type="text"
                      value={values.cpf}
                    />
                  )}
                </Field>
                {errors.cpf && touched.cpf}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="nome">Nacionalidade</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  name="nacionalidade"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nacionalidade}
                />
                {errors.nacionalidade && touched.nacionalidade}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="nome">Email</label>
                <Field
                  className="form-control"
                  required
                  type="email"
                  name="email"
                  disabled
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="nome">Telefone</label>
                <Field name="telefone">
                  {({ field }) => (
                    <MaskedInput
                      mask={Masks.telefone}
                      {...field}
                      guide={false}
                      className="form-control"
                      required
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telefone}
                    />
                  )}
                </Field>
                {errors.telefone && touched.telefone}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="nome">Cidade natal</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  name="cidadeNatal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cidadeNatal}
                />
                {errors.cidadeNnatal && touched.cidadeNnatal}
              </div>
            </div>
            <hr />
            <div className="form-row">
              <div className="col-12 col-md-5 my-2">
                <label htmlFor="endereco">Endereço</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  name="endereco"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endereco}
                />
                {errors.endereco && touched.endereco}
              </div>
              <div className="col-12 col-md-2 my-2">
                <label htmlFor="numero">Número</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  maxLength="8"
                  name="numero"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numero}
                />
                {errors.numero && touched.numero}
              </div>
              <div className="col-12 col-md-5 my-2">
                <label htmlFor="complemento">Complemento</label>
                <Field
                  className="form-control"
                  type="text"
                  name="complemento"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.complemento}
                />
                {errors.complemento && touched.complemento}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="bairro">Bairro</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  name="bairro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bairro}
                />
                {errors.bairro && touched.bairro}
              </div>
              <div className="col-12 col-md-4 my-2">
                <label htmlFor="cidade">Cidade</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  name="cidade"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cidade}
                />
                {errors.cidade && touched.cidade}
              </div>
              <div className="col-12 col-md-2 my-2">
                <label htmlFor="estado">UF</label>
                <Field
                  as="select"
                  value={values.uf ? values.uf : "default"}
                  onChange={handleChange}
                  className="form-control custom-select"
                  name="uf"
                  required
                >
                  <option disabled value="default">
                    Selecione
                  </option>
                  {dados.lista_estados.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
                {errors.estado && touched.estado}
              </div>
              <div className="col-12 col-md-2 my-2">
                <label htmlFor="cep">CEP</label>
                <Field name="cep">
                  {({ field }) => (
                    <MaskedInput
                      mask={Masks.cep}
                      {...field}
                      className="form-control"
                      required
                      type="text"
                      name="cep"
                      value={values.cep}
                    />
                  )}
                </Field>
                {errors.cep && touched.cep}
              </div>
            </div>
            <hr />
            <div className="form-row">
              <div className="col-12 col-md-3 my-2">
                <label htmlFor="banco">Banco</label>
                <Field
                  as="select"
                  value={values.banco ? values.banco : "default"}
                  onChange={handleChange}
                  className="form-control custom-select"
                  name="banco"
                  required
                >
                  <option disabled value="default">
                    Selecione
                  </option>
                  {dados.lista_bancos.map(({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </Field>
                {errors.banco && touched.banco}
              </div>
              <div className="col-12 col-md-3 my-2">
                <label htmlFor="agencia">Agência</label>
                <Field name="agencia">
                  {({ field }) => (
                    <MaskedInput
                      mask={Masks.agencia}
                      {...field}
                      className="form-control"
                      required
                      type="text"
                      value={values.agencia}
                    />
                  )}
                </Field>
                {errors.agencia && touched.numero}
              </div>
              <div className="col-12 col-md-3 my-2">
                <label htmlFor="conta">Conta</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  name="conta"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.conta}
                />
                {errors.conta && touched.conta}
              </div>
              <div className="col-12 col-md-3 my-2">
                <label htmlFor="tipo">Tipo de conta</label>
                <Field
                  as="select"
                  value={values.tipo_conta}
                  onChange={handleChange}
                  className="form-control custom-select"
                  name="tipo_conta"
                  required
                >
                  <option value="corrente">Corrente</option>
                  <option value="poupanca">Poupança</option>
                </Field>
                {errors.tipo_conta && touched.tipo_conta}
              </div>
            </div>
            <div className="row py-3 d-flex justify-content-center pr-3 submit-row">
              <button
                style={{ backgroundColor: partner_data.cor_primaria }}
                className="btn btn-success px-3"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Salvando" : "Salvar"}
              </button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </>
  );

export default connect(({ extra_user_data, partner_data }) => ({
  dados: extra_user_data,
  partner_data,
}))(Form);
