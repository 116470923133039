import React, { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { connect } from "react-redux";
import { postApi } from "../helper/request.helper";

import { set_user_data, set_current_step } from "../store/actions";

const Login = ({ dispatch }) => {
  const [response, setResponse] = useState("");

  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  const [active, setActive] = useState(true);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !senha) {
      return setResponse({ class: "error", msg: "*Preencha todos os campos!" });
    }
    setActive(false);
    return postApi("/login", { email, senha, postType: "login" }).then(
      async ({ data }) => {
        if (data.success) {
          const { data_cliente, key_refresh, key_actived, p_confirmed } = data;
          toast.success("Logado com sucesso!");
          return setTimeout(() => {
            dispatch(set_current_step(1));
            return dispatch(
              set_user_data({
                data_cliente,
                key_actived,
                key_refresh,
                p_confirmed,
              })
            );
          }, 3000);
        }
        setActive(true);
        return toast.error(data.msg);
      }
    );
  };

  return (
    <>
      <ToastContainer autoclose={8000} />
      <form className="d-flex flex-column justify-content-center">
        <h3 className="mb-2">Login</h3>
        <input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          type="email"
          className="form-control mb-2"
        />
        <input
          onChange={(e) => setSenha(e.target.value)}
          placeholder="Senha"
          type="password"
          className="form-control mb-2"
        />
        <button onClick={handleLogin} type="submit" className="btn btn-success">
          {active ? (
            <span>Entrar</span>
          ) : (
            <div className="spinner-border" role="status">
              <span className="sr-only">Carregando</span>
            </div>
          )}
        </button>
        {response ? (
          <small className={`my-2 ${response.class}`}>{response.msg}</small>
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export default connect(() => ({}))(Login);
