import React from 'react'

export default function Status (props) {
  return(
    <div style={{marginLeft:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
    {
      props.status ?
      <>
        <b style={{fontFamily:'CamptonBold',color:'#27cc4a'}}>EM DIA</b>
        <span style={{marginLeft:'5px',display:'flex',justifyContent:'center',alignItems:'center',width:'20px',height:'20px',justifyContent:'center',alignItems:'center',background:'#27cc4a',borderRadius:'50%'}}>
          <img style={{width:'12px'}} src={props.icons.check_white}/>
        </span>
      </>
      :
      <>
        <b style={{color:'#151515'}}>ATRASADO</b>
      </>
    }
    </div>
  )
} 