import React from "react";

import { moeda } from "../helper/numbers.helper";

export default function Aside({ data }) {
  const { name, deadline, minimum_investment, profit } = data;

  return (
    <div className="aside">
      <div style={{ display: "flex" }}>
        <div className="col">
          <div className="row">Projeto</div>
          <div className="row">Inv. minímo</div>
          <div className="row">Rentabilidade</div>
          <div className="row">Retorno</div>
        </div>
        <div className="col">
          <div style={{ maxWidth: "8em" }} className="d-block text-truncate">
            {name}
          </div>
          <div className="d-block text-truncate">
            {moeda(minimum_investment)}
          </div>
          <div className="d-block text-truncate">{`${profit}`}</div>
          <div className="d-block text-truncate">{`${deadline} meses`}</div>
        </div>
      </div>
    </div>
  );
}
