import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Whatsapp from "../components/whatsapp";

import { connect } from "react-redux";

import { Helmet } from "react-helmet";
import { getApi, private_getApi } from "../helper/request.helper";
import Header from "../components/header";
import { reset_investment } from "../store/actions";
import Acesso from "./steps.investir/acesso";
import Boleto from "./steps.investir/boleto";
import Contrato from "./steps.investir/contrato";
import Dados from "./steps.investir/dados";
import ValidarPerfil from "./steps.investir/validarPerfil";
import Valor from "./steps.investir/valor";
import TermoRisco from "./steps.investir/termo";

import { next_step, set_user_data, load_project } from "../store/actions";

const Investir = ({ current_step, user_data, dispatch, match, project }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadNecessary = async () => {
      if (localStorage.getItem("session") && !user_data) {
        console.log("possui no storage");
        await dispatch(
          set_user_data(JSON.parse(localStorage.getItem("session")))
        );
        await dispatch(next_step(1));
      }
      if (user_data && current_step === 0) {
        await dispatch(next_step(1));
      }
      return setLoaded(true);
    };

    loadNecessary();

    const cleanUp = async () => {
      await dispatch(reset_investment());
      return true;
    };

    return cleanUp;
  }, []);

  const renderStep = () => {
    switch (current_step) {
      case 0:
        return <Acesso match={match} />;
      case 1:
        return <Dados match={match} />;
      case 2:
        return <ValidarPerfil match={match} />;
      case 3:
        return <Valor match={match} />;
      case 4:
        return <TermoRisco match={match} />;
      case 5:
        return <Contrato match={match} />;
      case 6:
        return <Boleto match={match} />;
      default:
        return <Acesso match={match} />;
    }
  };

  return (
    <>
      <ToastContainer autoclose={8000} />
      {!loaded ? (
        <div className="d-flex justify-content-center py-5">
          <div className="spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
        </div>
      ) : (
        <>
          <Header project={match.params.project} />
          <div className="container">
            <div className="row d-flex justify-content-center py-3">
              {renderStep()}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect(({ current_step, user_data, project }) => ({
  current_step,
  user_data,
  project,
}))(Investir);
