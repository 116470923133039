import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getApi } from "../../helper/request.helper";
import { ASSETS_ARQUIVOS_EMPREEDIMENTO } from "../../helper/config";
import { moeda } from "../../helper/numbers.helper";
import { Link } from "react-router-dom";
import { reset_investment } from '../../store/actions';

function Investir({ partner_data, dispatch }) {
  const [activeProjects, setActiveProjects] = useState(null);

  useEffect(() => {
    const loadHome = async () => {
      getApi("/site/home").then((response) => {
        const { data, success } = response.data;
        if (success) {
          return setActiveProjects(data.empreendimentos_ativos);
        }
        return toast.error("Erro ao carregar os projetos ativos");
      });
    };

    dispatch(reset_investment())
    loadHome();

    return setActiveProjects(null);
  }, []);

  const paymentTable = ["Bullet", "Juros Mensais", "Price", "Pool"];

  return activeProjects ? (
    <>
      <div className="home-page w-100">
        <div className="container my-5">
          <div className="col">
            <h4>Escolha o seu investimento</h4>
          </div>
          <div className="d-flex col home-cards mt-3">
            {activeProjects.length > 0 ? (
              activeProjects.map((project, index) => {
                console.log(project.empreendimento_tipo_pagamento);
                return (
                  <div
                    key={index}
                    className="card mr-3"
                    style={{ maxWidth: "400px", minWidth: "300px" }}
                  >
                    <img
                      src={`${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${project.empreendimento_img}`}
                      className="card-img-top"
                      alt=""
                    />
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <h6>{project.empreendimento_nome}</h6>
                      </li>
                      <li className="list-group-item">
                        Rentabilidade: {project.empreendimento_rentabilidade}
                      </li>
                      <li className="list-group-item">
                        Investimento mínimo:{" "}
                        {moeda(project.empreendimento_investimento_minimo)}
                      </li>
                      <li className="list-group-item">
                        Modalidade:{" "}
                        {
                          paymentTable[
                            parseInt(project.empreendimento_tipo_pagamento)
                          ]
                        }
                      </li>
                    </ul>
                    <div className="card-body d-flex align-items-end">
                      <Link
                        to={`/projeto/${project.empreendimento_url}`}
                        className="btn btn-success"
                        style={{
                          backgroundColor: partner_data.cor_primaria,
                          borderColor: partner_data.cor_primaria,
                        }}
                      >
                        Saiba mais
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <span style={{ color: "#333" }}>
                Nenhuma captação ativa no momento
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="w-100 d-flex py-5 my-5 justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading... </span>
      </div>
    </div>
  );
}

export default connect(({ partner_data }) => ({ partner_data }))(Investir);
