import React from 'react';

const notFound = () => (
  <div className="container">
    <h1>Opss...</h1>
    <h2>Não reconhecemos sua busca</h2>
  </div>
);

export default notFound;
