/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
class CardDownloadAnexo extends React.Component {
  render() {
    return (
      <div className="row card-row hidden-md-up">
        {this.props.data.map((item, key) => (
          <div key={key} className="col-12 col-md-4 mb-3">
            <div className="card">
              <div className="card-body text-center">
                <div style={{ height: "100%" }}>
                  <h5
                    style={{
                      color: this.props.partner_data.cor_primaria,
                    }}
                    className="card-title"
                  >
                    {item.nome}
                  </h5>
                  <div className="card-text py-1">
                    <p>{item.descricao}</p>
                  </div>
                </div>
                <div className="download">
                  <a
                    className="btn btn-success"
                    style={{
                      backgroundColor: this.props.partner_data.cor_primaria,
                    }}
                    rel="noopener noreferrer"
                    target="_blank"
                    external="true"
                    href={
                      (item.href ? item.href : this.props.href) +
                      (item.anexo.length > 0 ? `/${item.anexo}` : "")
                    }
                  >
                    {this.props.textButton}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

CardDownloadAnexo.defaultProps = {
  href: "",
  textButton: "Baixar",
  imgButton: `${process.env.REACT_APP_URL}/assets/site/images/empreendimento/icn-download.png`,
  iconButton: false,
};

export default connect(({ partner_data }) => ({
  partner_data,
}))(CardDownloadAnexo);
