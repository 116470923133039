/* eslint-disable */
import React from "react";
import Tab from "./tab";

import {
  _abaPagamentos,
  _abaEmpresa,
  _abaEmpreendimento,
  _abaEmpreendedor,
  _abaRemuneracao,
  _abaInvestidores,
  _abaViabilidade,
  _abaJuridico,
  _abaVendas,
  _abaAvancoObra,
  _abaOperacao,
  _abaDocumentos,
  _abaRelatorios,
  _abaFotosObra,
  _abaForum,
} from "./tabContents";
import "./captacaoAbas.css";

export default class CaptacaoAbas extends React.Component {
  render() {
    if (typeof this.props.empresa === "undefined") {
      return (
        <div
          className="col-md-12 col-xs-12"
          style={{
            height: "240px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h4>Carregando...</h4>
        </div>
      );
    } // end loading

    const itens = {
      mobilidade: {
        nav: [
          "Como funciona",
          "Empreendedor",
          "Operação",
          "Remuneração",
          "Jurídico",
        ],
        content: [
          _abaEmpreendimento({
            galeria: this.props.galeria,
            texto: this.props.dados.texto,
            mapa: this.props.dados.localizacao_maps,
          }),
          _abaEmpreendedor({
            empreendedor: this.props.empreendedor,
            texto: this.props.dados.texto_empreendedor,
          }),
          _abaOperacao({
            operacao: this.props.operacao,
            texto: this.props.dados.texto_operacao,
          }),
          _abaRemuneracao({
            tabela: {
              remuneracao: this.props.remuneracao,
              title_col_one_remuneracao: this.props.dados
                .title_col_one_remuneracao,
              title_col_two_remuneracao: this.props.dados
                .title_col_two_remuneracao,
              title_col_tree_remuneracao: this.props.dados
                .title_col_tree_remuneracao,
            },
            texto: this.props.dados.texto_bl_remuneracao,
          }),

          _abaJuridico({
            juridico: this.props.juridico,
            texto: this.props.dados.texto_bl_juridico,
          }),
        ],
      },
      inco: {
        nav: [
          "Empresa",
          "Empreendimento",
          "Time",
          "Remuneração",
          "Jurídico",
        ],
        content: [
          _abaEmpresa(this.props.empresa),
          _abaEmpreendimento({
            galeria: this.props.galeria,
            texto: this.props.dados.texto,
            mapa: this.props.dados.localizacao_maps,
          }),
          _abaEmpreendedor({
            empreendedor: this.props.empreendedor,
            texto: this.props.dados.texto_empreendedor,
          }),
          _abaRemuneracao({
            tabela: {
              remuneracao: this.props.remuneracao,
              title_col_one_remuneracao: this.props.dados
                .title_col_one_remuneracao,
              title_col_two_remuneracao: this.props.dados
                .title_col_two_remuneracao,
              title_col_tree_remuneracao: this.props.dados
                .title_col_tree_remuneracao,
            },
            texto: this.props.dados.texto_bl_remuneracao,
          }),

          _abaJuridico({
            juridico: this.props.juridico,
            texto: this.props.dados.texto_bl_juridico,
          }),
        ],
      },
      oav: {
        nav: [],
        content: [],
      },

      cvm: {
        nav: [
          "Empresa",
          "Impacto",
          "Time",
          "Investidores",
          "Financeiro",
          "Jurídico",
        ],
        content: [
          _abaEmpresa(this.props.empresa),
          _abaEmpreendimento({
            galeria: this.props.galeria,
            texto: this.props.dados.texto,
            mapa: this.props.dados.localizacao_maps,
          }),
          _abaEmpreendedor({
            empreendedor: this.props.empreendedor,
            texto: this.props.dados.texto_empreendedor,
          }),
          _abaInvestidores({ investimentos: this.props.investimentos }),
          _abaViabilidade({
            viabilidade: this.props.viabilidade,
            texto: this.props.dados.texto_bl_viabilidade,
          }),
          _abaJuridico({
            juridico: this.props.juridico,
            texto: this.props.dados.texto_bl_juridico,
          }),

        ],
      },
    };

    let extras = [];
    if (this.props.investimento) {
      if (this.props.investimento.ccb.length > 0) {
        const ccb = {
          id: 0,
          id_empreendimento: 0,
          ordem: 0,
          nome: "CCB",
          descricao: "Baixar CCB",
          anexo: "",
          href: this.props.investimento.ccb.replace(/\s/g, ""),
        };
        if (this.props.dados.canal_assinatura !== "clicksign") {
          extras = [ccb];
        }
      } // faz igual aqui, ccb, cdbv e contrato fiança.
      if (this.props.investimento.cdbv) {
        const cdbv = {
          id: 0,
          id_empreendimento: 0,
          ordem: 0,
          nome: "CDBV",
          descricao: "Baixar CDBV",
          anexo: this.props.investimento.cdbv.replace(/\s/g, ""),
          href: `${process.env.REACT_APP_URL}`,
        };
        extras = [cdbv]; // aqui precisa concatenar acredito eu.
      }
      if (this.props.investimento.contrato_fianca) {
        const fianca = {
          id: 0,
          id_empreendimento: 0,
          ordem: 0,
          nome: "CONTRATO FIANÇA",
          descricao: "Baixar contrato fiança",
          anexo: this.props.investimento.contrato_fianca.replace(/\s/g, ""),
          href: `${process.env.REACT_APP_URL}`,
        };
        extras.push(fianca); // to lembrando nem dos métodos mais
        // da pra fazer uma validação para cada caso CDBV e outra para o FIANCA.
        // por que como esta validando apenas o CDBV e esta mostrando tbm o fiança
        // ai exibe os dois lá mesmo sem ter o fiança.
      }
    }

    // removendo modelo de contrato caso um assinado já exista
    if (this.props.investimento) {
      if (this.props.investimento.contrato_final) {
        this.props.juridico.map((value, key) => {
          if (value.descricao === "Modelo de Contrato") {
            return this.props.juridico.splice(key);
          }
          return false;
        });
      }
    }

    const setPath_documentos = [];
    if (this.props.documentos) {
      this.props.documentos.map(
        (value, key) =>
          (value.href = `${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/documento`)
      );
    }
    const setPath_viabilidade = [];
    if (this.props.viabilidade) {
      this.props.viabilidade.map(
        (value, key) =>
          (value.href = `${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/viabilidade`)
      );
    }
    const setPath_juridico = [];
    if (this.props.juridico) {
      this.props.juridico.map(
        (value, key) =>
          (value.href = `${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/juridico`)
      );
    }
    let setPath_contrato = [];
    if (
      this.props.investimento &&
      (this.props.investimento.contrato_final ||
        this.props.investimento.contrato)
    ) {
      setPath_contrato = [
        {
          anexo: this.props.investimento.contrato_final
            ? this.props.investimento.contrato_final
            : this.props.investimento.contrato,
          descricao: "Download do contrato",
          href: `${process.env.REACT_APP_URL}`,
          id: 0,
          id_empreendimento: 0,
          nome: "Contrato do investimento",
          ordem: "2",
        },
      ];
    }

    // juros mensais
    const itens_acompanhe = {
      mobilidade: {
        nav: [
          "Relatórios",
          "Como funciona",
          "Documentos",
          "Remuneração",
          ,
          "Fórum investidores",
        ],
        content: [
          _abaRelatorios({
            relatorios: this.props.cronograma,
            texto: "Cronograma",
          }),
          _abaEmpreendimento({
            galeria: this.props.galeria,
            texto: this.props.dados.texto,
            mapa: this.props.dados.localizacao_maps,
          }),
          _abaDocumentos({
            documentos: extras
              .concat(setPath_contrato)
              .concat(setPath_documentos)
              .concat(setPath_viabilidade)
              .concat(setPath_juridico),
            texto: "Documentos do empreendimento",
          }),
          _abaRemuneracao({
            tabela: {
              remuneracao: this.props.remuneracao,
              title_col_one_remuneracao: this.props.dados
                .title_col_one_remuneracao,
              title_col_two_remuneracao: this.props.dados
                .title_col_two_remuneracao,
              title_col_tree_remuneracao: this.props.dados
                .title_col_tree_remuneracao,
            },
            texto: this.props.dados.texto_bl_remuneracao,
          }),
          _abaForum({ id: this.props.dados ? this.props.dados.id : false }),
        ],
      },
      inco: {
        nav: [
          "Pagamentos",
          "Documentos",
          "Remuneração",
          "Relatórios",
          "Fórum investidores",
        ],
        content: [
          _abaPagamentos({ dados: this.props }),
          _abaDocumentos({
            documentos: extras
              .concat(setPath_contrato)
              .concat(this.props.documentos)
              .concat(this.props.viabilidade)
              .concat(this.props.juridico),
            texto: "Documentos do empreendimento",
          }),
          _abaRemuneracao({
            tabela: {
              remuneracao: this.props.remuneracao,
              title_col_one_remuneracao: this.props.dados
                .title_col_one_remuneracao,
              title_col_two_remuneracao: this.props.dados
                .title_col_two_remuneracao,
              title_col_tree_remuneracao: this.props.dados
                .title_col_tree_remuneracao,
            },
            texto: this.props.dados.texto_bl_remuneracao,
          }),
          _abaRelatorios({
            relatorios: this.props.cronograma,
            texto: "Cronograma",
          }),
          _abaForum({ id: this.props.dados ? this.props.dados.id : false }),
        ],
      },
      oav: {
        nav: [
          "Empreendimento",
          "Documentos",
          "Remuneração",
          "Relatórios",
          "Fórum investidores",
        ],
        content: [
          _abaEmpreendimento({
            galeria: this.props.galeria,
            texto: this.props.dados.texto,
            mapa: this.props.dados.localizacao_maps,
          }),
          _abaDocumentos({
            documentos: extras
              .concat(setPath_contrato)
              .concat(this.props.documentos)
              .concat(this.props.viabilidade)
              .concat(this.props.juridico),
            texto: "Documentos do empreendimento",
          }),
          _abaRemuneracao({
            tabela: {
              remuneracao: this.props.remuneracao,
              title_col_one_remuneracao: this.props.dados
                .title_col_one_remuneracao,
              title_col_two_remuneracao: this.props.dados
                .title_col_two_remuneracao,
              title_col_tree_remuneracao: this.props.dados
                .title_col_tree_remuneracao,
            },
            texto: this.props.dados.texto_bl_remuneracao,
          }),
          _abaRelatorios({
            relatorios: this.props.cronograma,
            texto: "Cronograma",
          }),
          _abaForum({ id: this.props.dados ? this.props.dados.id : false }),
        ],
      },

      cvm: {
        nav: [
          "Operação",
          "Documentos",
          "Remuneração",
          "Relatórios",
          "Fórum investidores",
        ],
        content: [
          _abaEmpreendimento({
            galeria: this.props.galeria,
            texto: this.props.dados.texto,
            mapa: this.props.dados.localizacao_maps,
          }),
          _abaDocumentos({
            documentos: extras
              .concat(setPath_contrato)
              .concat(this.props.documentos)
              .concat(this.props.viabilidade)
              .concat(this.props.juridico),
            texto: "Documentos do empreendimento",
          }),
          _abaRemuneracao({
            tabela: {
              remuneracao: this.props.remuneracao,
              title_col_one_remuneracao: this.props.dados
                .title_col_one_remuneracao,
              title_col_two_remuneracao: this.props.dados
                .title_col_two_remuneracao,
              title_col_tree_remuneracao: this.props.dados
                .title_col_tree_remuneracao,
            },
            texto: this.props.dados.texto_bl_remuneracao,
          }),
          _abaRelatorios({
            relatorios: this.props.cronograma,
            texto: "Cronograma",
          }),
          _abaForum({ id: this.props.dados ? this.props.dados.id : false }),
        ],
      },
    };

    let checkType = { nav: [], content: [] };

    if (this.props.acompanhe) {
      // PÁGINA DE ACOMPANHE
      if (parseInt(this.props.dados.empreendimento_mobilidade) === 1) {
        if (
          this.props.dados.pagamento_mensal !== "0" &&
          this.props.dados.pagamento_mensal !== "3"
        ) {
          itens_acompanhe.mobilidade.nav.unshift("Pagamentos");
          itens_acompanhe.mobilidade.content.unshift(
            _abaPagamentos({ dados: this.props })
          );
        }
        checkType = itens_acompanhe.mobilidade;
      } else {
        if (
          this.props.dados.operacao === "cvm" ||
          this.props.dados.operacao === ""
        ) {
          if (this.props.dados.pagamento_mensal !== "0") {
            itens_acompanhe.cvm.nav.unshift("Pagamentos");
            itens_acompanhe.cvm.content.unshift(
              _abaPagamentos({ dados: this.props })
            );
          }
          checkType = itens_acompanhe.cvm;
        }
        if (this.props.dados.operacao === "oav") {
          if (this.props.dados.pagamento_mensal !== "0") {
            itens_acompanhe.oav.nav.unshift("Pagamentos");
            itens_acompanhe.oav.content.unshift(
              _abaPagamentos({ dados: this.props })
            );
          }
          checkType = itens_acompanhe.oav; // são as mesmas abas por enquanto...
        }
        if (this.props.dados.pagamento_mensal === "3") {
          checkType = itens_acompanhe.inco;
        }
      }
    } else {
      // PÁGINA DE DETALHES
      if (parseInt(this.props.dados.empreendimento_mobilidade) === 1) {
        checkType = itens.mobilidade;
      } else {
        if (
          (this.props.dados.operacao === "cvm" ||
            this.props.dados.operacao === "") &&
          this.props.dados.pagamento_mensal !== "3"
        ) {
          checkType = itens.cvm;
        }
        if (this.props.dados.pagamento_mensal === "3") {
          checkType = itens.inco;
        }
        if (this.props.dados.operacao === "oav") {
          checkType = itens.cvm; // são as mesmas abas por enquanto...
        }
      }
    }

    return (
      <div className="compCaptacaoAbas">
        <Tab className="">{checkType}</Tab>
      </div>
    );
  }
}

CaptacaoAbas.defaultProps = {
  acompanhe: false,
};
