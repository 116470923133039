import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field, Form as FormikForm } from "formik";
import MaskedInput from "react-text-mask";
import { FaCheckCircle } from "react-icons/fa";
import { submit_verify_basic } from "../../../store/actions";
import { private_postApi } from "../../../helper/request.helper";
import { toast, ToastContainer } from "react-toastify";

function Basic({ dispatch, user, partner_data }) {
  const [emailVerified, setEmailVerified] = useState(false);
  const [sendingCode, setSendingCode] = useState(null);

  const Masks = {
    name: [/\w+/],
    telefone: [
      "(",
      /[1-9]/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
    cpf: [
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      ".",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
    ],
  };

  const handleSendEmail = async (e) => {
    setSendingCode(true);

    return private_postApi("/services/send-code-mail").then(({ data }) => {
      if (data.success) {
      } else {
      }
      return setSendingCode(false);
    });
  };

  return user ? (
    <div className="verificar content">
      <Formik
        initialValues={{ ...user.cliente, code: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          return private_postApi("/clientes/verificar-perfil-dados-basicos", {
            etapa1: {
              nome: values.nome,
              sobrenome: values.sobrenome,
              cpf: values.cpf,
              telefone: values.telefone,
              email: values.email,
              codigo_email: values.code,
            },
          }).then(({ data }) => {
            setSubmitting(false);
            if (data.success) {
              return dispatch(submit_verify_basic(values));
            }
            return toast.error(data.msg);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <FormikForm onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="nome">Primeiro nome</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  maxLength="50"
                  name="nome"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.nome}
                />
                {errors.nome && touched.nome}
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="sobrenome">Sobrenome</label>
                <Field
                  className="form-control"
                  required
                  type="text"
                  maxLength="50"
                  name="sobrenome"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sobrenome}
                />
                {errors.sobrenome && touched.sobrenome}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="cpf">CPF</label>
                <Field name="cpf">
                  {({ field }) => (
                    <MaskedInput
                      mask={Masks.cpf}
                      {...field}
                      className="form-control"
                      required
                      type="text"
                      value={values.cpf}
                    />
                  )}
                </Field>
                {errors.cpf && touched.cpf}
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="nome">Telefone</label>
                <Field name="telefone">
                  {({ field }) => (
                    <MaskedInput
                      mask={Masks.telefone}
                      {...field}
                      guide={false}
                      className="form-control"
                      required
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.telefone}
                    />
                  )}
                </Field>
                {errors.telefone && touched.telefone}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="email">Email</label>
                <Field
                  className="form-control"
                  required
                  type="email"
                  name="email"
                  disabled
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email}
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="email">Código</label>
                {!parseInt(values.emailVerificado, 10) ? (
                  <>
                    <Field
                      className="form-control mb-2"
                      required
                      type="text"
                      name="code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.code}
                    />
                    <button
                      onClick={(e) => handleSendEmail(e)}
                      className="send-code-email"
                    >
                      {sendingCode ? "Enviando..." : "Enviar código"}
                    </button>
                  </>
                ) : (
                  <span className="col form-control rounded-pill d-flex align-items-center py-2">
                    Email já verificado
                    <FaCheckCircle
                      className="ml-1"
                      size="1rem"
                      color="#27cc4a"
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="row py-3 d-flex justify-content-end pr-3">
              <button
                // style={{ backgroundColor: partner_data.cor_primaria }}
                className="btn btn-success px-3"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Aguarde..." : "Continuar"}
              </button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  ) : (
    "carregando"
  );
}

export default connect(({ extra_user_data, partner_data }) => ({
  user: extra_user_data,
  partner_data,
}))(Basic);
