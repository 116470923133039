export default function pickType(name) {
    var separador = name.lastIndexOf('.');
    var ext = separador <= 0 ? '' : name.substr(separador + 1);
    const mimeType = {
        'hqx'   : 'application/mac-binhex40',
        'cpt'   : 'application/mac-compactpro',
        'csv'   : 'text/comma-separated-values',
        'bin'   : 'application/macbinary',
        'exe'   : 'application/x-msdownload',
        'oda'   : 'application/oda',
        'pdf'   : 'application/pdf',
        'ai'    : 'application/postscript',
        'eps'   : 'application/postscript',
        'ps'    : 'application/postscript',
        'smi'   : 'application/smil',
        'smil'  : 'application/smil',
        'mif'   : 'application/vnd.mif',
        'ppt'   : 'application/vnd.ms-powerpoint',
        'wbxml' : 'application/wbxml',
        'wmlc'  : 'application/wmlc',
        'dcr'   : 'application/x-director',
        'dir'   : 'application/x-director',
        'dxr'   : 'application/x-director',
        'dvi'   : 'application/x-dvi',
        'gtar'  : 'application/x-gtar',
        'gz'    : 'application/x-gzip',
        'php'   : 'application/x-httpd-php',
        'php4'  : 'application/x-httpd-php',
        'php3'  : 'application/x-httpd-php',
        'phtml' : 'application/x-httpd-php',
        'phps'  : 'application/x-httpd-php-source',
        'js'    : 'application/x-javascript',
        'swf'   : 'application/x-shockwave-flash',
        'sit'   : 'application/x-stuffit',
        'tar'   : 'application/x-tar',
        'tgz'   : 'application/x-tar',
        'xhtml' : 'application/xhtml+xml',
        'xht'   : 'application/xhtml+xml',
        'zip'   : 'application/zip',
        'mid'   : 'audio/midi',
        'midi'  : 'audio/midi',
        'mpga'  : 'audio/mpeg',
        'mp2'   : 'audio/mpeg',
        'mp3'   : 'audio/mpeg',
        'aif'   : 'audio/x-aiff',
        'aiff'  : 'audio/x-aiff',
        'aifc'  : 'audio/x-aiff',
        'ram'   : 'audio/x-pn-realaudio',
        'rm'    : 'audio/x-pn-realaudio',
        'rpm'   : 'audio/x-pn-realaudio-plugin',
        'ra'    : 'audio/x-realaudio',
        'rv'    : 'video/vnd.rn-realvideo',
        'wav'   : 'audio/x-wav',
        'bmp'   : 'image/bmp',
        'gif'   : 'image/gif',
        'jpeg'  : 'image/jpeg',
        'jpg'   : 'image/jpeg',
        'jpe'   : 'image/jpeg',
        'png'   : 'image/png',
        'tiff'  : 'image/tiff',
        'tif'   : 'image/tiff',
        'css'   : 'text/css',
        'html'  : 'text/html',
        'htm'   : 'text/html',
        'shtml' : 'text/html',
        'txt'   : 'text/plain',
        'text'  : 'text/plain',
        'log'   : 'text/plain',
        'rtx'   : 'text/richtext',
        'rtf'   : 'text/rtf',
        'xml'   : 'text/xml',
        'xsl'   : 'text/xml',
        'mpeg'  : 'video/mpeg',
        'mpg'   : 'video/mpeg',
        'mpe'   : 'video/mpeg',
        'qt'    : 'video/quicktime',
        'mov'   : 'video/quicktime',
        'avi'   : 'video/x-msvideo',
        'mp4'   : 'video/mp4',
        'wmv'   : 'video/x-ms-asf',
        'movie' : 'video/x-sgi-movie',
        'doc'   : 'application/msword',
        'docx'  : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xlsx'  : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'word'  : 'application/msword',
        'xl'    : 'application/excel',
        'eml'   : 'message/rfc822',
        'json'  : 'application/json',
    }

    let SuperIndex;
    Object.keys(mimeType).forEach((value,index)=>{
        if(typeof value === 'object' ){
            value.forEach(subValue =>{
                if(subValue === ext) return SuperIndex = index
            })
        }
        else if ( value === ext) return SuperIndex = index
    })
    let mimetype = Object.values(mimeType)[SuperIndex]
    return {ext: ext,mimetype:mimetype}
}