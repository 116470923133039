/* eslint-disable */
import React, { Fragment } from 'react';

export default class ContentVendas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  gerarGraficoUnidades() {
    const round = Math.round(this.props.unidades_porcentagem / 10);

    const row = [];
    for (let i = 1; i <= 10; i++) {
      const active = i <= round ? 'active' : null;

      row.push(
        <li className={active || ''}>
          <img
            className={active ? 'hide' : ''}
            src={`${process.env.REACT_APP_URL}/assets/site/images/empreendimento/icn-buildings.png`}
            draggable="false"
          />

          <div className="preenchido">
            <img
              className={active ? '' : 'hide'}
              src={`${process.env.React}/assets/site/images/empreendimento/icn-buildings-green.png`}
              draggable="false"
            />
          </div>
        </li>,
      );
    }
    return row;
  }

  render() {
    const d = new Date();

    const params = { name: 'vendas' };
    if (!this.state.isMounted) {
      return null;
    }

    return (
      <>
        <div className={`row ${this.props.nameContainer} ${params.name}`}>
          {
            parseInt(this.props.unidades_vendidas) > 0
              ? (
                <>
                  <div className={`col-md-6 col-xs-12 textoTab left_${params.name}`}>
                    <section>
                      <article style={{ padding: 0, marginTop: '30px' }}>
                        <h3 className="title">
                          {`Vendas até a data ${d.getDay() + 1}/${d.getMonth()
                        + 1}/${d.getFullYear()}`}
                        </h3>
                        <div className="unidades">
                          {this.props.unidades_vendidas}
                          {' '}
                          unidades
                        </div>
                      </article>
                    </section>
                  </div>
                  <div className={`col-md-6 col-xs-12 textoTab right_${params.name}`}>
                    <section>
                      <article style={{ padding: 0, marginTop: '30px' }}>
                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <h3 className="title">
                            Unidades vendidas
                          {' '}
                            <span>
                {this.props.unidades_porcentagem}
                %
                          </span>
                          </h3>
                          </div>
                          <div className="col-md-8 col-xs-12">
                            <div className="list">
                            <ul>
                {typeof this.props.unidades_porcentagem !== 'undefined'
                              ? this.gerarGraficoUnidades()
                              : ''}
              </ul>
                          </div>
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <div className="legenda">
                            <div className="legenda-title">Legenda</div>
                            <div className="option">vendido</div>
                            <div className="option">a venda</div>
                          </div>
                          </div>
                        </div>
                      </article>
                    </section>
                  </div>
                </>
              )
              : <div dangerouslySetInnerHTML={{ __html: this.props.texto_vendas }} />
          }
        </div>
      </>
    );
  }
}
