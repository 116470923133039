import React, { useState, useEffect } from "react";
import { getApi } from "../../helper/request.helper";
import { moeda } from "../../helper/numbers.helper";
import { connect } from "react-redux";
import Header from "../../components/header";
import { Main, TitlePage, Content, Title, Item } from "./styles";
import Status from "./Status";

function Ofertas_Encerradas(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    getApi("/site/ofertas-encerradas").then((response) => {
      const { lista } = response.data;
      setData(lista);
    });
  }, []);

  return data && props.partner_data ? (
    <>
      <Header menu />
      <Main theme={props.partner_data}>
        <TitlePage className="container">{data.title_page}</TitlePage>
        <Content>
          <Title className="container">{data.title_section_cvm}</Title>
          {data.cvm.map((item, index) => {
            return (
              <Item
                background={
                  index % 2 === 0 ? "#fff" : props.partner_data.cor_primaria
                }
              >
                <div className="container">
                  <div className="col">
                    <p className="oferta-title">{item.empreendimento}</p>
                    <p className="oferta-razao-social">
                      {item.spe_razao_social
                        ? item.spe_razao_social
                        : item.empresa_razao_social}
                    </p>
                    <p className="oferta-cnpj">
                      <span>CNPJ: </span>
                      <span>
                        {item.spe_cnpj ? item.spe_cnpj : item.empresa_cnpj}
                      </span>
                    </p>
                  </div>
                  <div className="col">
                    <p style={{ display: "flex" }}>
                      <b>Prestação contas: </b>
                      <Status icons={props.icons} status={item.status} />
                    </p>
                    <p>
                      <b>Encerramento:</b> {item.data_encerramento}
                    </p>
                    <p>
                      <b>Arrecadado:</b> {moeda(item.total_arrecadado)}
                    </p>
                  </div>
                </div>
              </Item>
            );
          })}
        </Content>
        {/* <Content>
        <Title className="container">{data.title_section_oav}</Title>
        {data.oav.map((item, index) => {
          return (
            <Item background={index % 2 === 0 ? "#fff" : "rgb(246,253,248)"}>
              <div className="container">
                <div className="col">
                  <p className="oferta-title">{item.empreendimento}</p>
                  <p className="oferta-razao-social">
                    {item.spe_razao_social
                      ? item.spe_razao_social
                      : item.empresa_razao_social}
                  </p>
                  <p className="oferta-cnpj">
                    <span>CNPJ: </span>
                    <span>
                      {item.spe_cnpj ? item.spe_cnpj : item.empresa_cnpj}
                    </span>
                  </p>
                </div>
                <div className="col">
                  <p style={{ display: "flex" }}>
                    <b>Prestação contas: </b>
                    <Status icons={props.icons} status={item.status} />
                  </p>
                  <p>
                    <b>Encerramento:</b> {item.data_encerramento}
                  </p>
                  <p>
                    <b>Arrecadado:</b> {moeda(item.total_arrecadado)}
                  </p>
                </div>
              </div>
            </Item>
          );
        })}
      </Content> */}
      </Main>
    </>
  ) : (
    ""
  );
}

export default connect(({ partner_data }) => ({ partner_data }))(
  Ofertas_Encerradas
);
