/* eslint-disable */
import React, { Fragment } from "react";
export default class ContentRemuneracao extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }
  render() {
    let params = { name: "remuneracao" };
    if (!this.state.isMounted) {
      return null;
    }
    return (
      <Fragment>
        <div className={"row " + this.props.nameContainer + " " + params.name}>
          <div className={"col-md-12 col-xs-12 textoTab texto_" + params.name}>
            <section>
              <article style={{ padding: 0 }}>
                <table
                  className="table"
                  style={{ width: "auto", margin: "auto" }}
                >
                  <thead>
                    <tr>
                      <td>
                        <b>{this.props.tabela.title_col_one_remuneracao}</b>
                      </td>
                      <td>
                        <b>{this.props.tabela.title_col_two_remuneracao}</b>
                      </td>
                      <td>
                        <b>{this.props.tabela.title_col_tree_remuneracao}</b>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.tabela.remuneracao.map((item, key) => {
                      return (
                        <tr key={key}>
                          <td>{item.tipo}</td>
                          <td>{item.anual}</td>
                          <td>{item.unidades}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </article>

              <article
                style={{
                  lineHeight: "30px",
                  padding: 0,
                  marginTop: "30px",
                  fontSize: "1rem",
                }}
                dangerouslySetInnerHTML={{ __html: this.props.texto }}
              ></article>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}
