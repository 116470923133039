import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { postApi } from "../helper/request.helper";
import { toast, ToastContainer } from "react-toastify";
import { connect } from "react-redux";
import { set_user_data } from "../store/actions";

import { Link } from "react-router-dom";

const Acesso = ({ dispatch }) => {
  const [loaded, setLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setPass] = useState("");
  const [sending, setSending] = useState(false);

  const verifySession = async () => {
    const session = await localStorage.getItem("session");

    if (session) {
      window.location.pathname = "/dashboard/meus-investimentos";
      return false;
    }
    return setLoaded(true);
  };
  useEffect(() => {
    verifySession();
  }, []);

  const handleEmailChange = (e) => {
    if (!e.target.value) {
      return false;
    }
    return setEmail(e.target.value);
  };

  const handlePassChange = (e) => {
    if (!e.target.value) {
      return false;
    }
    return setPass(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !senha) {
      return toast.error("Preencha seu email e senha corretamente");
    }
    setSending(true);
    return postApi("/login", { email, senha, postType: "login" })
      .then(async ({ data }) => {
        if (data.success) {
          const { data_cliente, key_refresh, key_actived, p_confirmed } = data;
          toast.success("Logado com sucesso!");
          return setTimeout(() => {
            dispatch(
              set_user_data({
                data_cliente,
                key_actived,
                key_refresh,
                p_confirmed,
              })
            );
            window.location.pathname = "/";
          }, 3000);
        }
        setSending(false);
        return toast.error(data.msg);
      })
      .catch((err) => {
        toast.error(err.response.data.msg);
      });
  };

  return !loaded ? (
    <div className="w-100 d-flex align-items-center mt-5 justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  ) : (
    <>
      <Header />
      <ToastContainer autoclose={8000} />
      <div className="container">
        <div className="col py-3 card-login">
          <div className="card-login-title">Acessar conta</div>
          <div className="card-login-content">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <input
                type="email"
                className="form-control my-2"
                placeholder="Digite seu e-mail"
                onChange={(e) => handleEmailChange(e)}
              />
              <input
                type="password"
                className="form-control my-2"
                placeholder="Digite sua senha"
                onChange={(e) => handlePassChange(e)}
              />
              <button type="submit" className="btn btn-success my-2">
                {!sending ? (
                  "Entrar"
                ) : (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Carregando...</span>
                  </div>
                )}
              </button>
            </form>
          </div>
          <div className="card-login-footer">
            <span>
              Não tem uma conta? <Link to="/cadastrar">Criar conta</Link>
            </span>
            <Link to="/recovery/senha">Esqueceu sua senha?</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(() => ({}))(Acesso);
