import React, { useState, useEffect } from "react";
import { URL_SYS } from '../../helper/config'
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { private_getApi, private_postApi } from "../../helper/request.helper";
import { set_investments, set_userIRHash } from "../../store/actions";


import Resumo from "../../components/resumo";
import Table from "../../components/table";

const MeusInvestimentos = ({ dispatch, dados, userIrHash, partner_data}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await private_getApi("/dashboard").then((response) => {
        if (response.data.success) {
          dispatch(set_investments(response.data.dados));
          setLoaded(true);
        }
      });
    };
    const getUserIrHash = async () => {
      await private_postApi("/services/hash").then((response) => {
        if (response.data.success) {
          dispatch(set_userIRHash(response.data.msg))
        }
      })
    }

    getData();
    getUserIrHash();

  }, []);

  return loaded ? (
    <>
      <Helmet>
        <title>Meus investimentos</title>
      </Helmet>
      <div className="container">
        <Resumo data={{ amount: dados.total, profit: dados.lucro_total }} />

        <div style={{ borderLeft: `3px solid ${partner_data.cor_primaria}`}} className="ir-box m-3 px-2">
          <span>
            Seu informativo de imposto de renda está pronto. {" "}
          </span>
          <b><a rel="noopener noreferrer noreferrer" target="_blank" href={`${URL_SYS}/imposto_renda?hash=${userIrHash}`}>Visualizar</a></b>

        </div>


        <div className="row py-2">
          <div className="col">
            {dados.investimentos.aguardando.length >= 1 ? (
              <Table data={dados.investimentos.aguardando} title="Pendentes" />
            ) : (
              <div className="my-5">
                <h4>Investimentos Pendentes</h4>
                <span>Você não possui investimentos pendentes</span>
              </div>
            )}
            {dados.investimentos.confirmado.length >= 1 ? (
              <Table data={dados.investimentos.confirmado} title="Ativos" />
            ) : (
              <div className="my-5">
                <h4>Investimentos Ativos</h4>
                <span>Você não possui investimentos ativos</span>
              </div>
            )}
            {dados.investimentos.pago.length >= 1 ? (
              <Table title="Finalizados" />
            ) : (
              <div className="my-5">
                <h4>Investimentos Finalizados</h4>
                <span>Você não possui investimentos finalizados</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );
};
export default connect(({ investimentos, userIrHash, partner_data }) => ({ dados: investimentos, userIrHash, partner_data }))(
  MeusInvestimentos
);
