import styled from "styled-components";
import devices from "./DeviceSizes";

export const TitlePage = styled.h1`
  font-size: 1.9rem;
  font-family: CamptonBold;
  color: #151515;
  text-transform: uppercase;
`;

export const Title = styled.h2`
  color: #27cc4a;
  font-size: 1.4rem;
  text-transform: uppercase;
  display: flex;
  margin-bottom: 30px;
  &:after {
    background-color: #27cc4a;
    content: "";
    align-self: center;
    margin-left: 15px;
    margin-bottom: 5px;
    height: 2px;
    flex: 1;
  }
`;

export const Item = styled.div`
  display: flex;
  padding: 30px 0px;
  background: ${(props) => props.background};
  & .container {
    display: flex;
    flex-flow: row wrap;
  }
  & .oferta-title {
    font-family: CamptonBold;
    font-size: 20px;
    color: #151515;
    margin-bottom: 15px;
  }
  & .col {
    min-width: 300px;
  }
  & p {
    margin: 8px 0px;
    font-size: 16px;
    color: #444;
    text-transform: uppercase;
    font-family: CamptonLight;
    & b {
      color: #151515;
      text-transform: initial;
    }
  }
`;

export const Status = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-left: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${(props) => (props.type ? "#27cc4a" : "#b00020")};
  background-color: ${(props) => (props.type ? "#27cc4a" : "#b00020")};
  & img {
    width: 12px;
  }
  &:before {
    content: ${(props) => (props.type ? "EM DIA" : "<b>ATRASADO</b>")};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 80px;
`;

export const Main = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 130px;
  margin-bottom: 80px;
  letter-spacing: 1.2px;
  @media ${devices.tablet} {
    margin-top: 130px;
  }
  ${Title} {
    color: ${(props) => props.theme.cor_primaria};
    &:after {
      background: ${(props) => props.theme.cor_primaria};
    }
  }
`;
