import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { private_getApi } from "../../helper/request.helper";

import { Link } from "react-router-dom";
import { set_extra_user_data, load_project } from "../../store/actions";
import { toast, ToastContainer } from "react-toastify";
import Form from "../../components/form";

const Dados = ({ dispatch, match }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const set_UserData = async () => {
      await private_getApi("/clientes/perfil").then(async (response) => {
        const { success, data } = response.data;
        if (success) {
          await dispatch(set_extra_user_data(data));
          return setLoaded(true);
        }
        return setLoaded(true);
      });
    };
    set_UserData();
  }, []);

  useEffect(() => {
    const loadProject = () => {
      private_getApi(`/empreendimento/${match.params.project}`).then(
        async (response) => {
          const { success, data, msg } = response.data;
          if (success) {
            await dispatch(load_project(data));
            return setLoaded(true);
          }
          toast.error(`Erro: ${msg}`);
          return setTimeout(() => {
            window.location.pathname = "/";
            return true;
          }, 3000);
        }
      );
    };
    loadProject();
  });

  return !loaded ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="col py-3">
        <div className="row pb-3 d-flex justify-content-between">
          <div className="col-10">
            <h3>{true ? "Confirme seus dados." : "Preencha seus dados."}</h3>
          </div>
          <div className="col-2 d-flex justify-content-end">
            <Link className="btn" to={`/projeto/${match.params.project}`}>
              <AiOutlineClose size="24px" />
            </Link>
          </div>
        </div>
        <div className="container">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default connect(() => ({}))(Dados);
