/* eslint-disable */
import React from "react";
import MediaQuery from "react-responsive";

export default class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentTabActive: 0,
    };
  }

  changeTab(e) {
    this.setState({ contentTabActive: e });
  }

  render() {
    return (
      <>
        <ul className={`nav nav-tabs  ${this.props.className}`}>
          <MediaQuery minDeviceWidth={768}>
            {this.props.children.nav.map((item, key) => (
              <li
                key={key}
                onClick={() => this.changeTab(key)}
                className={`tabInvestirDetalheNav ${
                  this.state.contentTabActive === key ? "active" : ""
                }`}
              >
                <span
                  data-toggle="tab"
                  className={`_${key}${
                    this.state.contentTabActive === key ? " active" : ""
                  } tab-link`}
                >
                  {item}
                </span>
              </li>
            ))}
          </MediaQuery>
          <MediaQuery maxDeviceWidth={767}>
            {this.props.children.nav.map((item, key) => (
              <li
                key={key}
                onClick={() => this.changeTab(key)}
                className={`item tabInvestirDetalheNav ${
                  this.state.contentTabActive === key ? "active" : ""
                }`}
              >
                <span
                  data-toggle="tab"
                  key={key}
                  className={`_${key}${
                    this.state.contentTabActive === key ? " active" : ""
                  } tab-link`}
                >
                  {item}
                </span>
              </li>
            ))}
          </MediaQuery>
        </ul>

        <div
          className={`tab-content ${this.props.className} col`}
          style={{ float: "left", width: "100%" }}
        >
          {this.props.children.content.map((item, key) => (
            <div
              key={key}
              id={`_${key}`}
              style={{ width: "100%", float: "left" }}
              className={`tab-pane fade in ${
                this.state.contentTabActive === key ? "active show" : ""
              }`}
            >
              {item}
            </div>
          ))}
        </div>
      </>
    );
  }
}
Tab.defaultProps = {
  children: { nav: [], content: [] },
  className: "default",
};
