import React, { useState, useEffect } from "react";
import { Card, Image, Resume } from "./styles.js";
import Barra from "../barra_captacao";
import { MdAlarm, MdFileDownload } from "react-icons/md";
import { ASSETS_ARQUIVOS_EMPREEDIMENTO } from "../../helper/config";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { moeda } from "../../helper/numbers.helper";
import CVM from "../../assets/platform/cvm.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  FaFacebookF,
  FaTwitter,
  FaWhatsapp,
  FaLinkedinIn,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

export default function CardProject({
  data,
  active,
  handleChangeActive,
  theme,
}) {
  const [modalShow, setModalShow] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const handleModal = () => {
    return setModalShow(!modalShow);
  };

  const verifyMultiCaptacoes = (prop) => {
    if (data.captacoes) {
      return data.captacoes[active][prop];
    }
    return data.dados[prop];
  };

  const filterTipoProp = (tipo) => {
    switch (tipo) {
      case "0":
        return "Bullet";
      case "1":
        return "Juros mensais";
      case "2":
        return "Price";
      case "3":
        return "Pool";
      default:
        throw new Error("O tipo de pagamento informado é inválido.");
    }
  };

  return (
    <Card theme={theme} className="row ">
      <Modal show={disclaimer} onHide={() => setDisclaimer(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          {data.dados.operacao === "cvm" ? (
            <>
              <p>
                Essa oferta se enquadra nos requisitos da instrução 588 da CVM,
                sendo a Inco plataforma devidamente regulada para tal.
              </p>
              <p>
                As sociedades empresárias de pequeno porte e as ofertas
                apresentadas nesta plataforma estão automaticamente dispensadas
                de registro pela Comissão de Valores Mobiliários - CVM.
              </p>
              <b>A CVM não analisa previamente as ofertas.</b>
            </>
          ) : (
            <>
              <p>
                CCB com endosso ao Investidor.<br/>
                Essa oferta se enquadra no modelo de operações de financiamento
                e empréstimo entre pessoas (P2P) por meio de plataforma
                eletrônica, atividade pelo Banco Central do Brasil, conforme
                dispõe a Resolução nº 4.656, de 26 de abril de 2018.
              </p>
              <b>
                Essa oferta não se enquadra, portanto, sob a instrução 588 da
                CVM, sendo regulada pelo Bacen.
              </b>
            </>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={modalShow} onHide={handleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Powered by Inco</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            A Yunus Negócios Sociais é uma plataforma que viabiliza crédito para
            negócios sociais. Essa operação faz parte do programa Powered By
            INCO, em que a INCO fornece sua tecnologia e regulação jurídica para
            parceiros captarem recursos por meio de P2P Lending fora do ambiente
            da plataforma INCO.
          </p>
          <p>
            <b>
              A INCO não faz análise de risco nessas operações, que não compõem
              o portfólio da plataforma INCO.
            </b>
          </p>
          <p>
            <b>
              Leia com atenção o relatório de crédito e o termos de risco da
              operação antes de investir.
            </b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Link
            to={`/investir/${verifyMultiCaptacoes("url")}`}
            style={{ backgroundColor: theme.cor_primaria }}
            className="btn btn-success"
          >
            Continuar
          </Link>
        </Modal.Footer>
      </Modal>
      <Image theme={theme} className="col col-12 col-md-8 col-xl-8">
        <div className="head">
          <div className="title">
            <h3>{data.dados.nome}</h3>
            {data.dados.operacao === "cvm" && (
              <img src={CVM} alt="cvm" className="cvm-disclaimer" />
            )}
          </div>
          <div className="disclaimer">
            <p>
              {data.dados.operacao === "cvm"
                ? "I588 CVM"
                : "Operação de Crédito"}
              <span onClick={() => setDisclaimer(true)} className="question">
                ?
              </span>
            </p>
          </div>
        </div>
        <div className="image">
          <img
            width="100%"
            src={`${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${data.dados.img}`}
          />
        </div>
        <div className="share">
          <b className="d-none d-sm-block">Compartilhar</b>
          <ul>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://api.whatsapp.com/send?text=Confira essa oferta de investimento%20%3A%20https://${
                window.location.host
              }/projeto/${verifyMultiCaptacoes("url")}`}
              className="share-social"
            >
              <FaWhatsapp />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`http://twitter.com/share?text=Confira essa operação de investimento&url=https://${
                window.location.host
              }/projeto/${verifyMultiCaptacoes("url")}`}
              className="share-social"
            >
              <FaTwitter />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=https://${
                window.location.host
              }/projeto/${verifyMultiCaptacoes("url")}`}
              className="share-social"
            >
              <FaFacebookF />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`http://www.linkedin.com/shareArticle?mini=true&amp;url=https://${
                window.location.host
              }/projeto/${verifyMultiCaptacoes(
                "url"
              )};title=Confira essa operação de investimento'`}
              className="share-social"
            >
              <FaLinkedinIn />
            </a>
          </ul>
        </div>
      </Image>
      <Resume theme={theme} className="col col-12 col-md-4 col-xl-4">
        <div className="tabs">
          {data.captacoes &&
            data.captacoes.length > 1 &&
            data.captacoes.map((captacao, index) => {
              return (
                <button
                  type="button"
                  key={index}
                  value={index}
                  onClick={(e) => handleChangeActive(e)}
                  className={`head-tab ${index === active && "tab-active"}`}
                >
                  <b>{captacao.titulo}</b>
                </button>
              );
            })}
        </div>
        <div className="tab-body">
          <div className="d-flex flex-column ">
            <Barra
              data={{
                alvo: data.dados.alvo_arrecadacao,
                captado: data.arrecadado,
                limite: data.dados.meta_arrecadacao,
              }}
            />
            <div className="infos">
              <div className="rentabilidade">
                <b>{verifyMultiCaptacoes("rentabilidade")}</b>
                <span>Rentabilidade</span>
              </div>
              <div className="prazo">
                <b>{verifyMultiCaptacoes("tempo_retorno_site")}</b>
                <span>Prazo</span>
              </div>
              <div className="tipo">
                <b>
                  {/* {filterTipoProp(verifyMultiCaptacoes("pagamento_mensal"))} */}
                  Juros anuais e principal no vencimento da operação.
                </b>
                <span>Tipo de pagamento</span>
              </div>
              <div className="minimo">
                <b>{moeda(verifyMultiCaptacoes("investimento_minimo"))}</b>
                <span>Investimento mínimo</span>
              </div>
            </div>
            <hr />
            <div className="extra">
              <div className="tempo-restante">
                <span>
                  <MdAlarm />
                </span>
                <p>{data.dados.tempo}</p>
              </div>
              {data.dados.pdf_ajuda_arquivo ? (
                <div className="prospecto">
                  <a
                    target="_blank"
                    href={`${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${data.dados.pdf_ajuda_arquivo}`}
                  >
                    <span>
                      <MdFileDownload />
                    </span>
                    <b>{data.dados.pdf_ajuda_titulo}</b>
                  </a>
                </div>
              ) : (
                ""
              )}
              <div className="prospecto">
                <a
                  target="_blank"
                  href="https://admin.yunusnegociossociais.inco.vc/sys/assets/arquivos/empreendimentos/juridico/Relat%C3%B3rio_de_Avalia%C3%A7%C3%A3o_de_Impacto_do_Projeto_Piloto_FazGame_julho19.pdf"
                >
                  <span>
                    <MdFileDownload />
                  </span>
                  <b>Relatório de impacto</b>
                </a>
              </div>
              <div className="prospect"></div>
              {theme.operacao_parceiro === "cvm" && (
                <span className="disclaimer">
                  * Essa operação envolve riscos, as rentabilidades podem não se
                  concretizar.
                </span>
              )}
            </div>
            <span className="mb-3">
              * Esse operação envolve riscos, as rentabilidades podem não se
              concretizar.
            </span>
          </div>
          <div className="cta">
            <button
              type="button"
              onClick={handleModal}
              className="btn btn-success"
            >
              Investir
            </button>
          </div>
        </div>
      </Resume>
    </Card>
  );
}
