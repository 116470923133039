import React from "react";
import { connect } from "react-redux";
import DocumentFront from "../../components/modal/documentFront";
import DocumentBack from "../../components/modal/documentBack";
import DocumentSelfie from "../../components/modal/documentSelfie";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import Nav from "../../components/footerNav";

const ValidarPerfil = ({ dados, match }) => (
  <div className="container">
    <div className="col py-3">
      <div className="row pb-3">
        <div className="col-10">
          <h3>Verificar perfil</h3>
          <span>
            {parseInt(dados.user_data.data_cliente.perfil_verificado, 10)
              ? "Perfil verificado"
              : "Anexe fotos do seu documento de identificação (RG/CNH)"}
          </span>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Link className="btn" to={`/projeto/${match.params.project}`}>
            <AiOutlineClose size="24px" />
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            {!parseInt(dados.user_data.data_cliente.perfil_verificado, 10) &&
            !dados.extra_user_data.cliente.steps_verificacao_perfil.etapa2 ? (
              <>
                <div className="row py-2">
                  <div className="col col-md-10">
                    <span>
                      <b>1. Frente do documento</b>
                    </span>
                  </div>
                  <div className="col-auto col-md-2">
                    <DocumentFront />
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col col-md-10">
                    <span>
                      <b>2. Verso do documento</b>
                    </span>
                  </div>
                  <div className="col-auto col-md-2">
                    <DocumentBack />
                  </div>
                </div>
                <div className="row py-2">
                  <div className="col col-md-10">
                    <span>
                      <b>3. Selfie com o documento</b>
                    </span>
                  </div>
                  <div className="col-auto col-md-2">
                    <DocumentSelfie />
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center my-3">
                <h3>Seu perfil está em análise</h3>
              </div>
            )}
            <Nav
              enabled={
                dados.extra_user_data.cliente.steps_verificacao_perfil.etapa2
                  ? true
                  : dados.verify_front_document &&
                    dados.verify_back_document &&
                    dados.verify_selfie_document
              }
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default connect(
  ({
    verify_front_document,
    verify_back_document,
    verify_selfie_document,
    user_data,
    extra_user_data,
  }) => ({
    dados: {
      verify_front_document,
      verify_back_document,
      verify_selfie_document,
      user_data,
      extra_user_data,
    },
  })
)(ValidarPerfil);
