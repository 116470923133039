import React from 'react';

import { moeda } from '../helper/numbers.helper';

const Resumo = ({ data }) => (
  <div className="row justify-content-center my-3">
    <div className="card col-10 col-md-4 m-1 py-3">
      <div>Saldo atual</div>
      <div><h2>{moeda(data.amount)}</h2></div>
    </div>
    <div className="card col-10 col-md-4 m-1 py-3">
      <div>Rentabilidade</div>
      <div><h2>{moeda(data.profit)}</h2></div>
    </div>
  </div>
);

export default Resumo;
