import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { IoMdSend } from "react-icons/io";

import Upload from "../pendency/FILEBASE_Pendency/filebase64";

import OAV from "../pendency/contrato_oav";
import CVM from "../pendency/contrato_cvm";

import { private_getApi, private_postApi } from "../../helper/request.helper";

const Pendency = ({ pendency, cb }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = async () => {
    setData(null);
    setShow(false);
    return cb();
  };

  const onMount = async () => {
    setShow(true);
    if (!pendency) {
      return setShow(false);
    }
    const d = new Date();
    const id_hash =
      (d.getTime() * pendency).toString().substring(7, 13) + pendency;
    return private_getApi(`/clientes/pendencias/${id_hash}`).then(
      (response) => {
        if (response.data.success) {
          return setData(response.data.data);
        }
        return toast.error("Erro ao carregar investimento.");
      }
    );
  };

  useEffect(() => {
    onMount();

    return () => true;
  }, [pendency]);

  const Content = ({ investment }) => {
    const [contract, setContract] = useState(false);
    const [boleto, setBoleto] = useState(false);
    const [comprovante, setComprovante] = useState(null);

    const handleBoleto = () => {
      if (typeof boleto !== "string") {
        setBoleto(true);
        const d = new Date();
        return private_getApi(
          `/clientes/gerar_boleto/${investment.id}/${d.getTime()}`
        ).then(async (response) => {
          if (response.data.success) {
            toast.success("Boleto gerado com sucesso!");
            return setBoleto(response.data.link_boleto);
          }
          return toast.error("Erro ao gerar boleto");
        });
      }
      if (typeof boleto === "string") {
        return window.open(boleto, "_blank");
      }
      return false;
    };

    const getComprovante = (selectedFile) => {
      if (parseInt(selectedFile.size / 1024 / 1024, 10) > 15) {
        return toast.error("Selecione um arquivo de até 15 MB");
      }
      return setComprovante(selectedFile);
    };

    const sendComprovante = () => {
      if (!comprovante) {
        return false;
      }
      const d = new Date();
      return private_postApi(
        `/clientes/enviar_comprovantes/${investment.id}/${d.getTime()}`,
        {
          comprovante,
        }
      ).then((response) => {
        if (response.data.success) {
          setComprovante(true);
          return toast.success("Comprovantes anexados com sucesso!");
        }
        return toast.error(response.data.msg);
      });
    };

    if (parseInt(investment.status_contrato, 10) === 1) {
      if (
        parseInt(investment.status_comprovante, 10) === 1 ||
        comprovante === true
      ) {
        return (
          <div className="py-3">
            <h3>Por enquanto é só</h3>
            <span>Contrato assinado e comprovante anexado.</span>
            <p>
              Você já anexou seu comprovante, agora é só esperar até que
              validemos o pagamento.
            </p>
          </div>
        );
      }
      return (
        <>
          <h3>Realize o pagamento</h3>
          <span>
            Com o pagamento do boleto, você evita que sua reserva seja
            cancelada.
          </span>
          <div className="boleto-pendencia py-3">
            <button
              disabled={boleto && typeof boleto !== "string"}
              type="button"
              onClick={handleBoleto}
              className={`btn btn-${!boleto ? "outline-" : ""}success`}
            >
              {boleto
                ? typeof boleto !== "string"
                  ? "Gerando..."
                  : "Abrir boleto"
                : "Gerar boleto"}
            </button>
          </div>
          <hr />
          <p>
            Caso já tenha realizado o pagamento, você pode anexar um comprovante
            para ajudar na validação
          </p>
          <div className="anexo-pendencia">
            <div role="group" className="btn-group">
              <Upload
                multiple={false}
                title="Selecione um arquivo"
                onDone={getComprovante}
              />
              <button
                onClick={sendComprovante}
                disabled={!comprovante}
                type="button"
                className="btn btn-success"
              >
                <IoMdSend size={20} />
              </button>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className="col">
        {!contract ? (
          <>
            <h3 className="bold">Assine o contrato.</h3>
            {investment.canal_assinatura === "clicksign" ? (
              <CVM
                submitAssign={() => setContract(true)}
                pendency={investment}
              />
            ) : (
              <OAV
                submitAssign={() => setContract(true)}
                pendency={investment}
              />
            )}
          </>
        ) : (
          ""
        )}
        {contract ? (
          <>
            <h3>Realize o pagamento</h3>
            <span>
              Com o pagamento do boleto, você evita que sua reserva seja
              cancelada.
            </span>
            <div className="boleto-pendencia py-3">
              <button
                disabled={boleto && typeof boleto !== "string"}
                type="button"
                onClick={handleBoleto}
                className={`btn btn-${!boleto ? "outline-" : ""}success`}
              >
                {boleto
                  ? typeof boleto !== "string"
                    ? "Gerando..."
                    : "Abrir boleto"
                  : "Gerar boleto"}
              </button>
            </div>
            <hr />
            <p>
              Caso já tenha realizado o pagamento, você pode anexar um
              comprovante para ajudar na validação.
            </p>
            <div className="anexo-pendencia">
              <div role="group" className="btn-group">
                <Upload
                  multiple={false}
                  title="Selecione um arquivo"
                  onDone={getComprovante}
                />
                <button
                  onClick={sendComprovante}
                  disabled={!comprovante}
                  type="button"
                  className="btn btn-success"
                >
                  <IoMdSend size={20} />
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      <ToastContainer autoclose={8000} />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pendencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pendencia-investimento">
            {data ? (
              <Content investment={data} />
            ) : (
              <div className="w-100 d-flex align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Carregando...</span>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(() => ({}))(Pendency);
