import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";

import {
  private_getMoneyp,
  private_postMoneyp,
} from "../../helper/request.helper";

const OAV = ({ pendency, submitAssign }) => {
  const [motion, setMotion] = useState(null);
  const [code, setCode] = useState("");
  const [accept, setAccept] = useState(false);

  const handleCode = async (e) => {
    const { value } = e.target;
    return setCode(value);
  };

  const handleAssign = async () => {
    if (!code || !accept) {
      return false;
    }
    return private_postMoneyp("/realizar_assinatura", {
      id_investimento: pendency.id,
      codigo_proposta: motion.codigo_proposta,
      token: code,
    }).then((response) => {
      const { success, msg } = response.data;
      if (success) {
        submitAssign();
        return toast.success(msg);
      }
      return toast.error(msg);
    });
  };

  const handleSendToken = async (codigo_proposta) => {
    if (pendency.assigned) return false;
    return private_getMoneyp(
      `/sendTokenEmail?id_investimento=${pendency.id}&codigo_proposta=${codigo_proposta}`
    )
      .then((response) => {
        const { success, msg } = response.data;
        if (success) {
          return toast.success(msg);
        }
        return toast.error(msg);
      })
      .catch((err) => toast.error(err.response.data.msg));
  };

  const handleAccept = () => setAccept(!accept);

  const createMotion = async () =>
    private_getMoneyp(`/criar_proposta/${pendency.id}`).then(
      async ({ data }) => {
        const {
          success,
          resposta,
          link_ccb,
          codigo_proposta,
          codigo_operacao,
        } = data;
        if (success) {
          await setMotion({ link_ccb, codigo_proposta, codigo_operacao });
          return handleSendToken(codigo_proposta);
        }
        return toast.error(resposta);
      }
    );
  useEffect(() => {
    createMotion();
    return () => true;
  }, []);

  return (
    <>
      <ToastContainer autoclose={8000} />
      {motion ? (
        <>
          <div id="contrato_oav">
            <iframe title="contrato ccb" src={motion.link_ccb} />
          </div>
          <a href={motion.link_ccb} rel="noopener noreferrer" target="_blank">
            Visualizar em nova guia
          </a>
          <hr />
          <div className="oav-assign">
            <label htmlFor="accept">
              <input
                onChange={handleAccept}
                disabled={pendency.assigned}
                checked={accept}
                type="checkbox"
                id="accept"
              />
              <span className="ml-2">
                Este CCB é gerada e assinada eletronicamente, tendo a sua
                autenticidade, integridade e validade garantidas na forma da
                Lei. Eu reconheço como válida a assinatura eletrônica dessa CCB.
              </span>
            </label>
            <div className="token-actions">
              <small className="my-2">
                Seu código de assinatura foi enviado para {pendency.email}
              </small>
              <div className="form-inline">
                <div className="form-group mr-2">
                  <input
                    className="form-control"
                    disabled={!accept || pendency.assigned}
                    onChange={(e) => handleCode(e)}
                    value={code}
                    placeholder="Código de assinatura"
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <button
                    onClick={handleAssign}
                    disabled={!code || !accept || pendency.assigned}
                    className="btn btn-success"
                    type="button"
                  >
                    Assinar
                  </button>
                </div>
              </div>
              <button
                type="button"
                disabled={pendency.assigned}
                onClick={() => handleSendToken(motion.codigo_proposta)}
                className="link"
              >
                Reenviar código para e-mail
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="spinner-border" role="status">
          <span className="sr-only">Carregando...</span>
        </div>
      )}
    </>
  );
};

export default OAV;
