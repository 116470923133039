import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import Login from "../../components/login";
import Cadastrar from "../../components/cadastrar";

const Acesso = ({ match }) => {
  const [action, setAction] = useState(false);

  const handleAction = async () => {
    await setAction(!action);
  };

  return (
    <div className="container">
      <div className="col py-3">
        <div className="row d-flex justify-content-end">
          <Link className="btn" to={`/projeto/${match.params.project}`}>
            <AiOutlineClose size="24px" />
          </Link>
        </div>
        <div className="row">
          <div className="d-flex justify-content-center col">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Já tenho uma conta"
              onChange={handleAction}
            />
          </div>
        </div>
        <div className="row">
          <div className="d-flex my-5 justify-content-center col">
            {action ? <Login /> : <Cadastrar callBack={() => {}} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acesso;
