import { getStorage } from "../helper/storage.helper";

export const isAuthenticated = () => {
  const data = getStorage("session");
  if (data) return true;
  return false;
};

export const Logout = () => {
  window.localStorage.removeItem("session");
  window.location.pathname = "/";
  return true;
};
