import React, { useState } from "react";
import Front from "../../../components/modal/documentFront";
import Back from "../../../components/modal/documentBack";
import Selfie from "../../../components/modal/documentSelfie";
import { submit_verify_docs } from "../../../store/actions";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { private_postApi } from "../../../helper/request.helper";

function Files({ dispatch, files, user, basic }) {
  const [sending, setSending] = useState(false);

  const handleSubmitDocs = async () => {
    setSending(true);
    if (files.every((item) => item !== null)) {
      return private_postApi("/clientes/verificar-perfil-docs-suitability", {
        etapa2: {
          suitability: {},
          foto_documento: files[0],
          foto_documento_verso: files[1],
          foto_selfie: files[2],
        },
        etapa3: { ...user, emailVerificado: 1 },
      }).then(({ data }) => {
        if (data.success) {
          toast.success("Verificação solicitada com sucesso!");
          return setTimeout(() => {
            setSending(false);
            return dispatch(submit_verify_docs(files));
          }, 3000);
        }
        setSending(false);
        return toast.error(data.msg);
      });
    }
    return toast.error("Verifique se todos os arquivos foram selecionados");
  };

  return (
    <div className="verificar content">
      <ToastContainer autoclose={8000} />
      <div className="row mb-3 d-flex align-items-center">
        <div className="col">
          <b>1.Frente do documento</b>
        </div>
        <div className="col-4 pl-0 d-flex justify-content-end">
          <Front />
        </div>
      </div>
      <hr />
      <div className="row mb-3 d-flex align-items-center">
        <div className="col">
          <b>2.Verso do documento</b>
        </div>
        <div className="col-4 pl-0 d-flex justify-content-end">
          <Back />
        </div>
      </div>
      <hr />
      <div className="row mb-3 d-flex align-items-center">
        <div className="col">
          <b>3.Selfie com documento</b>
        </div>
        <div className="col-4 pl-0 d-flex justify-content-end">
          <Selfie />
        </div>
      </div>
      <hr />
      <div className="mt-3 d-flex justify-content-between">
        <button className="btn btn-light">Voltar</button>
        <button
          onClick={handleSubmitDocs}
          disabled={
            files[0] === null ||
            files[1] === null ||
            files[2] === null ||
            sending
          }
          className="btn btn-success"
        >
          {sending ? "Enviando..." : "Continuar"}
        </button>
      </div>
    </div>
  );
}

export default connect(
  ({
    verify,
    extra_user_data,
    verify_front_document,
    verify_back_document,
    verify_selfie_document,
  }) => ({
    user: extra_user_data.cliente,
    basic: verify.basic,
    files: [
      verify_front_document,
      verify_back_document,
      verify_selfie_document,
    ],
  })
)(Files);
