import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { set_extra_user_data } from "../../store/actions";
import { private_getApi } from "../../helper/request.helper";
import { toast, ToastContainer } from "react-toastify";
import "./verificar/verificar.css";
import Basic from "./verificar/basic.verificar";
import Files from "./verificar/files.verificar";
import Extra from "./verificar/extra.verificar";

function Verificar({ dispatch, verify }) {
  const [step, setStep] = useState(1);
  useEffect(() => {
    async function getUser() {
      return private_getApi("/clientes/perfil").then(({ data }) => {
        if (data.success) {
          if (parseInt(data.data.cliente.perfil_verificado, 10)) {
            window.location.pathname = "/dashboard/perfil";
            return false;
          }
          return dispatch(set_extra_user_data(data.data));
        }
        return toast.error("Erro ao carregar informações do cliente");
      });
    }
    getUser();

    return () => true;
  }, []);

  function renderStep() {
    switch (verify.step) {
      case 1:
        return <Basic />;
      case 2:
        return <Files />;
      case 3:
        return <Extra />;
      default:
        return <Basic />;
    }
  }

  return (
    <div className="container verificar my-3">
      <ToastContainer autoclose={8000} />
      <div className="col col-sm-10 px-0 header">
        <h2>Verificar Perfil</h2>
        <span className="counter-step">{`${verify.step}/3`}</span>
      </div>
      <div className="col px-0 col-sm-10">
        <span className="step-description">
          {(function () {
            switch (verify.step) {
              case 1:
                return "Precisamos de algumas informações para começar";
              case 2:
                return "Para garantir a segurança precisamos verificar seu perfil";
              case 3:
                return "Sua verificação está em processo. Em quanto isso vamos agilizar seu perfil para você começar a investir.";
              default:
                return "Precisamos de algumas informações para começar";
            }
          })()}
        </span>
      </div>
      <div className="col col-sm-10 mt-5 px-0 content">{renderStep()}</div>
    </div>
  );
}

export default connect(({ verify }) => ({
  verify,
}))(Verificar);
