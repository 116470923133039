export const BASE_URL = process.env.REACT_APP_URL;

export const URL_SYS = `${BASE_URL}`;

export const ASSETS = `${URL_SYS}/assets`;

export const ASSETS_ARQUIVOS = `${ASSETS}/arquivos`;

export const ASSETS_ARQUIVOS_EMPREEDIMENTO = `${ASSETS_ARQUIVOS}/empreendimentos`;

export const TOKEN_SECRET_API_INCO = "1298376-asd128937-1293081a-1233asgz";

export const TOKEN_SECRET_REFRESH_API_INCO =
  "987azsd73sg78234-2348235646512-1236";

export const VERSAO_API = "v1";

export const URI_ENDPOINT_INCO = BASE_URL + '/api/whitelabel/' + VERSAO_API;
