/* eslint-disable */
export default function Clicksign(o) {
  let r;
  let u;
  const t = `${window.location.protocol}//${process.env.REACT_APP_URL}`;
  const e = {};
  const n = function (t) {
    (e[t] || []).forEach((t) => {
      t();
    });
  };
  const c = function (t) {
    n(t.data);
  };
  return {
    endpoint: "https://app.clicksign.com",
    origin: t,
    mount(t) {
      const n = `/sign/${o}`;
      const e = `?embedded=true&origin=${this.origin}`;
      const i = this.endpoint + n + e;
      return (
        (u = document.getElementById(t)),
        (r = document.createElement("iframe")).setAttribute("src", i),
        r.setAttribute(
          "style",
          "width: 100%; height: 600px; z-index: 9999; position: absolute; border: 0; scrollbar-face-color:#173161"
        ),
        window.addEventListener("message", c),
        u.appendChild(r)
      );
    },
    unmount() {
      return (
        r &&
          (u.removeChild(r),
          (r = u = null),
          window.removeEventListener("message", n)),
        !0
      );
    },
    on(t, n) {
      return e[t] || (e[t] = []), e[t].push(n);
    },
    trigger: n,
  };
}
