import React from "react";
import MediaQuery from "react-responsive";
import { format } from "date-fns";
import { FaCloudDownloadAlt } from "react-icons/fa";
import Chart from "./chart";
import { ASSETS_ARQUIVOS_EMPREEDIMENTO } from "../../../helper/config";
import { moeda } from "../../../helper/numbers.helper";
import Abas from "../../../components/abas/captacaoAbas";

export default function Bullet({ data }) {
  const rendimento = data.fluxo_rendimento_ipca.reduce((acc, x) => {
    const unformatedValue = Number(x.juros_periodo.replace('.', '').replace(/[^0-9\,-]+/g,"").replace(',', '.'))

    return unformatedValue + acc
  }, 0).toFixed(2)

  return (
    <div className="container-fluid">
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${data.dados.img})`,
        }}
        className="row acompanhe-header mb-5"
      >
        {/* header mobile bullet */}
        <MediaQuery maxWidth={768}>
          <div className="col d-flex justify-content-center align-items-center">
            <div className="header-mobile">
              <div className="mainMobile">
                <div className="d-flex flex-column">
                  <small>Saldo atual</small>
                  <h2 className="font-weight-bold">
                  {moeda(
                    parseFloat(rendimento) + parseFloat(data.investimento.valor_investimento)
                  )}
                  </h2>
                </div>
                <div className="d-flex flex-column py-2">
                  <div className="col px-0">
                    <small>Valor investido</small>
                    <h4 className="font-weight-bolder">
                      {moeda(data.investimento.valor_investimento)}
                    </h4>
                  </div>
                  <div className="col px-0">
                    <small>Lucro</small>
                    <h4 className="font-weight-bolder">
                      {moeda(rendimento)}
                    </h4>
                  </div>
                </div>
                <div id="mobile-chart" className="d-flex chart">
                  <Chart data={data.ultimos_meses} />
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        {/* header desktop bullet */}

        <MediaQuery minWidth={769}>
          <div className="mx-auto header-desktop">
            <div className="mx-3">
              <span>Valor atual</span>
              <h2 className="font-weight-bolder mb-0">
                {moeda(
                  parseFloat(rendimento) + parseFloat(data.investimento.valor_investimento)
                )}
              </h2>
              <hr className="my-2"/>
                <b>{data.investimento.valor_investimento/ 100
              } vidas impactadas</b>
            </div>
            <div className="mx-3">
              <span>Valor investido</span>
              <h4 className="font-weight-bolder">
                {moeda(data.investimento.valor_investimento)}
              </h4>
            </div>
            <div className="mx-3">
              <span>Lucro</span>
              <h4 className="font-weight-bolder">
                {moeda(rendimento)}
              </h4>
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className="acompanhe-content">
        <MediaQuery maxDeviceWidth={768}>
          <div className="row content-mobile">
            <div className="py-3 col-12 d-flex">
              <span>Rentabilidade: IPCA</span>
            </div>
            <div className="secondary-background py-3 col-12 d-flex">
              <span>{`Duração: ${data.dados.tempo_retorno} meses`}</span>
            </div>
            <div className="py-3 col-12 d-flex">
              <span>{`Vencimento: ${
                data.dados.data_saque
                  ? format(
                      new Date(data.dados.data_saque.replace(" ", "T")),
                      "dd/MM/yyyy"
                    )
                  : "--"
              }`}</span>
            </div>
            <div className="secondary-background py-3 col-12 d-flex">
              <span>Modalidade: Bullet</span>
            </div>
            {data.dados.pdf_ajuda_titulo ? (
              <div className="py-3 col-12 d-flex">
                <a
                  className="d-flex align-items-center"
                  href={`${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${data.dados.pdf_ajuda_arquivo}`}
                >
                  <FaCloudDownloadAlt size="1.5rem" />
                  <span className="ml-2">{data.dados.pdf_ajuda_titulo}</span>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <div className="container content-desktop">
            <div className="row">
              <div className="col">
                <span>{data.categoria}</span>
                <h5 className="font-weight-bold">{data.dados.nome}</h5>
              </div>
              <div className="col">
                <span>Tempo para seu recebimento</span>
                <h5 className="font-weight-bold">{data.dados.tempo}</h5>
              </div>
              <div className="col">
                <span>Data de vencimento</span>
                <h5 className="font-weight-bold">
                  {`${
                    data.dados.data_saque
                      ? format(
                          new Date(data.dados.data_saque.replace(" ", "T")),
                          "dd/MM/yyyy"
                        )
                      : "--"
                  }`}
                </h5>
              </div>
            </div>
            <div className="row extra-content-desktop py-5">
              <div className="col-6">
                <span>Rentabilidade: IPCA</span>
              </div>
              <div className="col-6">
                <span>{`Duração: ${data.dados.tempo_retorno} meses`}</span>
              </div>
              <div className="col-6">
                <span>{`Investimento mínimo: ${moeda(data.dados.investimento_minimo)}`}</span>
              </div>
              <div className="col-6">
                <span>Modalidade: Bullet</span>
              </div>
            </div>
          </div>
        </MediaQuery>
        <div className="tabs py-3">
          <Abas {...data} acompanhe />
        </div>
      </div>
    </div>
  );
}
