import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { connect } from "react-redux";
import { getApi } from "../helper/request.helper";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { moeda } from "../helper/numbers.helper";
import { ASSETS_ARQUIVOS_EMPREEDIMENTO } from "../helper/config";
import WhatsApp from "../components/whatsapp";

import Banner from "../assets/inco/banner.jpg";
import Fluxograma from "../assets/inco/fluxograma.jpg";

function Home({ partner_data }) {
  const [activeProjects, setActiveProjects] = useState(null);

  useEffect(() => {
    const loadHome = async () => {
      getApi("/site/home").then((response) => {
        const { data, success } = response.data;
        if (success) {
          return setActiveProjects(data.empreendimentos_ativos);
        }
        return toast.error("Erro ao carregar os projetos ativos");
      });
    };

    loadHome();

    return setActiveProjects(null);
  }, []);

  const paymentTable = ["Bullet", "Juros Mensais", "Price", "Pool"];

  return partner_data && activeProjects ? (
    <>
      <Header menu />
      <div className="home-page">
        <div className="container my-5">
          <div className="col">
            <img
              className="img-fluid"
              loading="lazy"
              src={Banner}
              alt="Imagem com descrição: Plataforma de financiamento coletivo da Yunus Negócios Sociais"
            />
            <div className="my-4">
              <p>
                <b>
                  A plataforma de financiamento coletivo da Yunus Negócios
                  Sociais tem como objetivo conectar negócios sociais que
                  necessitam de financiamento com investidores de impacto para
                  expansão de suas operações e maximização do impacto social.
                </b>
              </p>
              <p>
                <b>
                  Trata-se de uma nova forma de olhar para os seus
                  investimentos, na qual o seu dinheiro seja um meio para
                  destravar o crescimento sustentável e inclusivo da sociedade.
                </b>
              </p>
            </div>
            <img
              className="img-fluid"
              loading="lazy"
              src={Fluxograma}
              alt="Fluxograma explicando a plataforma Yunus."
            />
          </div>

          <div className="col">
            <h4>Invista num negócio social</h4>
          </div>
          <div className="d-flex col home-cards mt-3">
            {activeProjects.length > 0 ? (
              activeProjects.map((project, index) => {
                return (
                  <div
                    key={index}
                    className="card mx-auto"
                    style={{ maxWidth: "400px", minWidth: "300px" }}
                  >
                    <img
                      src={`${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${project.empreendimento_img}`}
                      className="card-img-top"
                      loading="lazy"
                      alt=""
                    />
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item">
                        <h6>{project.empreendimento_nome}</h6>
                      </li>
                      <li className="list-group-item">
                        Rentabilidade: {project.empreendimento_rentabilidade}
                      </li>
                      <li className="list-group-item">
                        Investimento mínimo:{" "}
                        {moeda(project.empreendimento_investimento_minimo)}
                      </li>
                      <li className="list-group-item">
                        Modalidade:{" "}
                        {/* {
                          paymentTable[
                            parseInt(project.empreendimento_tipo_pagamento)
                          ] */}
                        Juros anuais e principal no vencimento da operação.
                      </li>
                    </ul>
                    <div className="card-body d-flex align-items-end">
                      <Link
                        to={`/projeto/${project.empreendimento_url}`}
                        className="btn btn-success"
                        style={{
                          backgroundColor: partner_data.cor_primaria,
                          borderColor: partner_data.cor_primaria,
                        }}
                      >
                        Saiba mais
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <span style={{ color: "#333" }}>
                Nenhuma captação ativa no momento
              </span>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  ) : (
    <div className="w-100 d-flex py-5 my-5 justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading... </span>
      </div>
    </div>
  );
}

export default connect(({ partner_data }) => ({ partner_data }))(Home);
