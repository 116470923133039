export const getStorage = (key) => {
  const data = window.localStorage.getItem(key);
  if (!data) {
    return false;
  }
  return JSON.parse(data);
};

export const setStorage = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data));
  return true;
};

export const removeStorage = (key, removeItem = null) => {
  const data = JSON.parse(getStorage(key));
  if (removeItem === null) {
    window.localStorage.removeItem(key);
    return true;
  }
  delete data[removeItem];
  return setStorage(key, data);
};
