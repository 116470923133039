/* eslint-disable */
import React, { Fragment } from "react";

export default class ContentAvancoObra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    let params = { name: "avancoObra" };
    if (!this.state.isMounted) {
      return null;
    }

    return (
      <Fragment>
        <div className={"row " + this.props.nameContainer + " " + params.name}>
          <div className={"col-md-12 col-xs-12 textoTab texto_" + params.name}>
            <section>
              <article style={{ padding: 0, marginTop: "30px" }}>
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <h3 className="title">Confira o avanço da obra</h3>
                  </div>
                  <div className="box_graficos col-md-12 col-xs-12">
                    {typeof this.props.avanco === "undefined" ||
                    this.props.avanco.length === 0 ? (
                      <h5>
                        <strong>Nenhum atualização até o momento.</strong>
                      </h5>
                    ) : (
                      this.props.avanco.map((item, key) => {
                        return (
                          <div className="progress">
                            <h3 className="progresss-title">{item.nome}</h3>
                            <div className="flex">
                              <div className="bar">
                                <div
                                  className="line"
                                  style={{ width: item.valor + "%" }}
                                ></div>
                              </div>
                              <div className="percentage">
                                {item.valor}% realizado
                              </div>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </article>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}
