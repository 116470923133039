import React, { useState } from 'react';

import { connect } from 'react-redux';

import { Modal } from 'react-bootstrap';

import { FaCheck } from 'react-icons/fa';
import { submit_verify_front } from '../../store/actions';

import Upload from '../FILEBASE64/filebase64';


import FotoDocumento from '../../assets/platform/documento-de-verificacao-min.png';

const DocumentFront = ({ dispatch }) => {
  const [file, setFile] = useState(null);
  const [lgShow, setLgShow] = useState(false);

  const handleSubmitFile = async () => {
    if (file) {
      await dispatch(submit_verify_front(file));
    }
    return setLgShow(!lgShow);
  };

  const getFile = async (selectedFile) => {
    setFile(selectedFile)
    return dispatch(submit_verify_front(selectedFile));
  };

  return (
    <>
      <button
        onClick={() => setLgShow(!lgShow)}
        type="button"
        className={`d-flex align-items-center btn ${file ? 'btn-outline-success' : 'btn-success'} btn-sm`}
      >
        {
        file
          ? (
            <>
              <FaCheck className="mr-2" />
              {' '}
              Selecionado
            </>
          )
          : 'Selecionar'
      }
      </button>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Frente do documento
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row justify-content-center">
              <img className="img-instrucoes" src={FotoDocumento} alt="intruções foto documento" />
            </div>
            <div className="row justify-content-center my-3">
              <Upload
                multiple={false}
                title="Selecione um arquivo"
                onDone={getFile}
                formatos=".jpg, .jpeg, .png, .pdf"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end"><button type="button" onClick={handleSubmitFile} className="btn btn-outline-success">Pronto</button></Modal.Footer>
      </Modal>
    </>
  );
};

export default connect(() => ({}))(DocumentFront);
