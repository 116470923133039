import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import ImageUploader from "react-images-upload";
import { Helmet } from "react-helmet";
import Form from "./form";
import { set_extra_user_data } from "../../store/actions";
import { URL_SYS } from "../../helper/config";
import { private_getApi, private_postApi } from "../../helper/request.helper";

const Perfil = ({ data, dispatch, partner_data }) => {
  const [loaded, setLoaded] = useState(false);
  const [userPicture, setUserPicture] = useState(null);

  useEffect(() => {
    const set_UserData = async () => {
      await private_getApi("/clientes/perfil")
        .then(async (response) => {
          if (response.data.success) {
            await dispatch(set_extra_user_data(response.data.data));
            return setLoaded(true);
          }
          return setLoaded(true);
        })
        .catch((err) => {
          console.log(err.toJson);
        });
    };
    set_UserData();
  }, []);

  const handleChangeUserPicture = (picture) => {
    if (!picture) {
      return false;
    }
    const reader = new FileReader();
    reader.readAsDataURL(picture[0]);
    reader.onload = async (e) => {
      const formData = { foto_update: e.target.result };
      setUserPicture(formData.foto_update);
      const update_data = { ...data.cliente };
      update_data.foto_update = formData.foto_update;
      delete update_data.foto;
      let $profilePicture = document.getElementById("user_picture");
      $profilePicture.style.backgroundImage = `url(${update_data.foto_update})`;
      await private_postApi("/clientes/perfil", update_data)
        .then((response) => {
          if (!response.data.success) {
            return console.log("Erro ao salvar foto de perfil");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  const handleInput = (e) => {
    if (e.target.type === "checkbox") {
      return private_postApi("/clientes/parts-update", {
        name: e.target.id,
        value: e.target.checked ? "S" : "N",
      });
    }
    return private_postApi("/clientes/parts-update", {
      name: e.target.id,
      value: e.target.value,
    });
  };
  return loaded && partner_data !== null ? (
    <>
      <Helmet>
        <title>Meu perfil</title>
      </Helmet>
      <div className="container  py-5">
        <div className="row py-2">
          <div className="col-12 my-3 col-md-4">
            <div className="col perfil-basic">
              <div className="col-12">
                <div className="col user-picture">
                  <figure
                    style={{
                      backgroundImage: `url(${
                        data.cliente.foto
                          ? `${URL_SYS}/${data.cliente.foto}`
                          : userPicture
                      })`,
                    }}
                    id="user_picture"
                  >
                    {!data.cliente.foto && !userPicture ? "L" : ""}
                  </figure>
                  <ImageUploader
                    buttonText="Selecione a foto"
                    label=""
                    className="update_foto_perfil"
                    withIcon={false}
                    fileSizeError="O tamanho da imagem ultrapassa o limite."
                    fileTypeError="Formato da imagem não é permitido."
                    onChange={handleChangeUserPicture}
                    imgExtension={[".jpg", ".jpeg", ".gif", ".png", ".gif"]}
                    maxFileSize={5242880}
                  />
                </div>
              </div>
              <div className="col-12 user-name">
                <div className="col perfil-nome">
                  <span className="mr-2">{`${data.cliente.nome} ${
                    data.cliente.sobrenome.split(" ")[
                      data.cliente.sobrenome.split(" ").length - 1
                    ]
                  }`}</span>
                  {parseInt(data.cliente.perfil_verificado, 10) ? (
                    <FaCheckCircle
                      size="1.2em"
                      color={partner_data.cor_primaria}
                    />
                  ) : data.cliente.steps_verificacao_perfil.etapa2 ? (
                    <MdError size="1.2rem" color="#ffc107" />
                  ) : (
                    <MdError size="1.2rem" color="#b20" />
                  )}
                </div>
                <div className="col d-flex justify-content-center my-2">
                  {!parseInt(data.cliente.perfil_verificado, 10) &&
                  !data.cliente.steps_verificacao_perfil.etapa2 ? (
                    <Link
                      to="/dashboard/verificar"
                      className="btn btn-sm btn-danger"
                    >
                      Verificar perfil
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col perfil-recovery-link">
                  <Link to="/recovery/senha">Redefinir senha</Link>
                </div>
              </div>
            </div>
            <div className="row py-3 user-extra">
              <div className="col">
                <label className="pt-2" htmlFor="data_nascimento">
                  Data de nascimento
                </label>
                <input
                  value={data.cliente.data_nascimento}
                  onChange={handleInput}
                  placeholder="00/00/0000"
                  id="data_nascimento"
                  type="date"
                  className="form-control"
                />

                <label className="pt-2" htmlFor="sexo">
                  Gênero
                </label>
                <select
                  id="sexo"
                  onChange={handleInput}
                  defaultValue={
                    data.cliente.sexo ? data.cliente.sexo : "nao-informar"
                  }
                  className="form-control"
                >
                  <option value="Masculino">Masculino</option>
                  <option value="Feminino">Feminino</option>
                  <option value="nao-informar">--</option>
                </select>
              </div>
            </div>
            <div className="col form-group">
              <label htmlFor="exibir_imagem">
                <input
                  defaultChecked={data.cliente.exibir_imagem === "S"}
                  onChange={handleInput}
                  type="checkbox"
                  id="exibir_imagem"
                  className="form-check-input"
                />
                <span>
                  Permitir que minha foto apareça na lista de investidores na
                  página de projetos
                </span>
              </label>

              <label className="pt-2" htmlFor="politicamente_exposta">
                <input
                  defaultChecked={data.cliente.politicamente_exposta === "S"}
                  onChange={handleInput}
                  type="checkbox"
                  id="politicamente_exposta"
                  className="form-check-input"
                />
                <span>Sou uma pessoa politicamente exposta</span>
              </label>
            </div>
          </div>
          <div className="col">
            <Form />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Carregando...</span>
      </div>
    </div>
  );
};

export default connect(({ extra_user_data, partner_data }) => ({
  data: extra_user_data,
  partner_data,
}))(Perfil);
