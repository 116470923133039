import React, { useState } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { FaArrowRight } from "react-icons/fa";
import { set_user_data, next_step } from "../store/actions";
import { postApi, private_postApi } from "../helper/request.helper";

const Cadastrar = ({ callBack, dispatch }) => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [toVerify, setToVerify] = useState(false);
  const [active, setActive] = useState(true);
  const [code, setCode] = useState("");
  const [response, setResponse] = useState("");
  const [page, setPage] = useState(0);
  const [type, setType] = useState("comum");

  const renderStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <input
              onChange={(e) => setNome(e.target.value)}
              placeholder="Nome"
              value={nome}
              type="text"
              className="form-control my-2"
            />

            <button
              onClick={handleCadastrar}
              type="submit"
              className="btn btn-success my-2"
            >
              Continuar
            </button>
          </>
        );
      case 1:
        return (
          <>
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              value={email}
              type="email"
              className="form-control my-2"
            />
            <input
              onChange={(e) => setSenha(e.target.value)}
              placeholder="Senha"
              value={senha}
              type="password"
              className="form-control my-2"
            />

            <button
              onClick={handleCadastrar}
              type="submit"
              className="btn btn-success my-2"
            >
              {active ? (
                "Criar conta"
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Carregando</span>
                </div>
              )}
            </button>
          </>
        );
    }
  };

  const handleCadastrar = async (e) => {
    e.preventDefault();
    if ((page === 0 && !nome) || (page === 1 && (!email || !senha || !type))) {
      return setResponse({ class: "error", msg: "*Preencha todos os campos!" });
    }

    if (page < 1) {
      return setPage(page + 1);
    }

    setActive(false);
    return postApi("/login", {
      email,
      nome,
      senha,
      tipo_investidor: type,
    }).then(async (dataResponse) => {
      if (dataResponse.data.success) {
        return postApi("/login", { email, senha, postType: "login" }).then(
          async ({ data }) => {
            if (data.success) {
              const {
                data_cliente,
                key_refresh,
                key_actived,
                p_confirmed,
              } = data;
              toast.success("Cadastrado com sucesso!");
              await dispatch(
                set_user_data({
                  data_cliente,
                  key_refresh,
                  key_actived,
                  p_confirmed,
                })
              );
              await setActive(true);
              return setToVerify(true);
            }
            await setActive(true);
            return toast.error(data.msg);
          }
        );
      }
      setActive(true);
      return toast.error(dataResponse.data.msg);
    });
  };

  const handleVerifyEmail = async (e, value) => {
    e.preventDefault();
    setActive(false);
    return private_postApi("/clientes/verificar-email", { token: value }).then(
      async (response) => {
        const { data } = response;
        if (data.success) {
          toast.success("Email verificado com sucesso");
          dispatch(next_step(1));
          return true;
        }
        setActive(true);
        return toast.error(data.msg);
      }
    );
  };

  return (
    <>
      <ToastContainer autoclose={8000} />

      <form
        style={{ maxWidth: "400px" }}
        className="d-flex flex-column justify-content-center"
      >
        <h3 className="mb-2">{toVerify ? "Verificar email" : "Cadastrar"}</h3>
        {toVerify ? (
          <>
            <span className="mb-2">
              Você acabou de receber um token de validação no seu email.
              Insira-o aqui para validação.
            </span>
            <div className="d-flex align-items-center verify-email">
              <input
                value={code}
                placeholder="Digite o código enviado no seu e-mail"
                className="form-control"
                type="text"
                onChange={(e) => setCode(e.target.value)}
              />
              <button
                className="btn btn-success"
                type="submit"
                onClick={(e) => handleVerifyEmail(e, code)}
              >
                {active ? (
                  "Verificar"
                ) : (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </>
        ) : (
          renderStep(page)
        )}
        {response ? (
          <small className={`my-2 ${response.class}`}>{response.msg}</small>
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export default connect(() => ({}))(Cadastrar);
