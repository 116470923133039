/* eslint-disable */
import React, { Fragment } from "react";
import ImageGallery from "react-image-gallery";

import "./tab-content.css";

export default class ContentEmpreendimento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: "bottom",
      showVideo: {},
    };
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !this.state.showVideo[url];
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {this.state.showVideo[item.embedUrl] ? (
          <>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="video-wrapper">
              <a
                className="close-video"
                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
              />

              <iframe
                width="560"
                height="315"
                style={{ zIndex: "9999" }}
                src={`${item.embedUrl.replace(
                  "watch?v=",
                  "embed/"
                )}?controls=1&modestbranding=1&autoplay=1&rel=0&color=red&hd=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button">
              <i className="fa fa-play" />
            </div>
            <img src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const params = { name: "empreendimento" };

    if (!this.state.isMounted) {
      return null;
    }

    const Itens = [];
    this.props.galeria.map((val, key) => {
      const img =
        val.tipo === "video"
          ? val.img
          : `${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/galeria/${val.img}`;
      if (val.tipo === "video") {
        Itens.push({
          original: img,
          thumbnail: img,
          embedUrl: val.tipo === "video" ? val.link : "",
          renderItem: this._renderVideo.bind(this),
        });
      } else {
        Itens.push({
          original: img,
          thumbnail: img,
          imageSet: [
            {
              srcSet: img,
              media: "(max-width: 1280px)",
            },
            {
              srcSet: img,
              media: "(min-width: 1280px)",
            },
          ],
        });
      }
    });

    return (
      <>
        <div className={`row ${this.props.nameContainer} ${params.name}`}>
          <div className={`col-md-12 col-xs-12 mb-3 carrousel_${params.name}`}>
            {/* INICIO FOTOS */}
            {this.props.galeria.length > 0 ? (
              <>
                <ImageGallery
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showNav={false}
                  lazyLoad
                  showThumbnails={this.props.galeria.length > 1}
                  items={Itens}
                />
              </>
            ) : (
              ""
            )}
            {/* FIM FOTOS */}
          </div>
          <div className={`col-md-12 col-xs-12 textoTab texto_${params.name}`}>
            <section>
              <article dangerouslySetInnerHTML={{ __html: this.props.texto }} />
            </section>
            {/*
                      this.props.mapa

                        ? (
                          <div>
                            <iframe
                              style={{ border: 0 }}
                              width="100%"
                              height="450"
                              frameBorder="0"
                              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBNepcCvcD7H_qNOgYGyTEXFrt4t9xSiB4&q=${this.props.mapa}`}
                              allowFullScreen
                            />
                          </div>
                        )
                        : ''
                        */}
          </div>
        </div>
      </>
    );
  }
}
