/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Aside from "../../components/aside";
import Nav from "../../components/footerNav";
import { private_postApi, private_getApi } from "../../helper/request.helper";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { set_investment, load_project } from "../../store/actions";

const Valor = ({ project, dispatch, match, user }) => {
  const asideData = {
    name: project.dados.nome,
    deadline: project.dados.tempo_retorno,
    minimum_investment: project.dados.investimento_minimo,
    profit: project.dados.rentabilidade,
  };

  const [investiment, setInvestiment] = useState("");
  const [originalValue, setOriginal] = useState("");

  const [qualified, setQualified] = useState(user.tipo_investidor);
  const [accept, setAccept] = useState(false);

  useEffect(() => {
    const data = {
      value: originalValue,
      qualified,
      accept,
    };
    dispatch(set_investment(data));
  }, [originalValue, qualified, accept]);

  useEffect(() => {
    const update_tipoInvestidor = async (value) => {
      if (value === user.tipo_investidor) return false;
      return private_postApi("/clientes/parts-update", {
        name: "tipo_investidor",
        value,
      });
    };
    if (project.dados.operacao !== "oav") {
      update_tipoInvestidor(qualified);
    }
  }, [qualified]);

  const handleValue = async (value) => {
    const changeQualified = (value) => {
      if (qualified === "comum" && value > 10000) {
        return setQualified("intermediario");
      }
      return false;
    };

    if (project.dados.operacao === "oav") {
      let formated = value.replace(/\D/g, "");
      formated = `${(formated / 100).toFixed(2)}`;
      formated = formated.replace(".", ",");
      formated = formated.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      formated = formated.replace(/(\d)(\d{3}),/g, "$1.$2,");
      const valor_cru = formated.replace(/[.-]/g, "").replace(",", ".");
      await setInvestiment(formated);
      return setOriginal(valor_cru);
    }
    if (qualified !== "comum" && value.length < 14) {
      let formated = value.replace(/\D/g, "");
      formated = `${(formated / 100).toFixed(2)}`;
      formated = formated.replace(".", ",");
      formated = formated.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      formated = formated.replace(/(\d)(\d{3}),/g, "$1.$2,");
      const valor_cru = formated.replace(/[.-]/g, "").replace(",", ".");
      await setInvestiment(formated);
      return setOriginal(valor_cru);
    }
    if (qualified === "comum" && value.length < 10) {
      let formated = value.replace(/\D/g, "");
      formated = `${(formated / 100).toFixed(2)}`;
      formated = formated.replace(".", ",");
      formated = formated.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
      formated = formated.replace(/(\d)(\d{3}),/g, "$1.$2,");
      const valor_cru = formated.replace(/[.-]/g, "").replace(",", ".");
      await setInvestiment(formated);
      await changeQualified(valor_cru);
      return setOriginal(valor_cru);
    }
    return false;
  };

  const handleQualification = (value) => {
    if (value === "comum") {
      if (parseInt(originalValue, 10) > 10000) {
        handleValue("1000000");
      }
      return setQualified(value);
    }
    if (value === "qualificado" || value === "intermediario") {
      return setQualified(value);
    }
    return false;
  };

  const handleAccept = () => setAccept(!accept);

  return (
    <div className="container">
      <div className="d-flex justify-content-between flex-wrap row">
        <div className="col-10">
          <h3>Quanto deseja aportar?</h3>
          <span>Seu limite de aporte é baseado no seu perfil.</span>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Link className="btn" to={`/projeto/${match.params.project}`}>
            <AiOutlineClose size="24px" />
          </Link>
        </div>
        <div className="col-12 col-md-6">
          <div className="form-group mt-3">
            <label className="h5" htmlFor="value">
              Valor
            </label>
            <input
              maxLength="11"
              value={investiment}
              onChange={(e) => handleValue(e.target.value)}
              type="text"
              name="value"
              className="form-control"
            />
          </div>
          {project.dados.operacao !== "oav" && (
            <div className="qualified mt-4">
              <span>Sou um investidor</span>
              <div className="qualified-control">
                <button
                  type="button"
                  className={`text-center btn btn-${
                    qualified === "comum" ? "success" : "outline-secondary"
                  } p-2 col-6`}
                  onClick={() => handleQualification("comum")}
                >
                  Comum
                </button>
                <button
                  type="button"
                  className={`text-center btn btn-${
                    qualified === "intermediario"
                      ? "success"
                      : "outline-secondary"
                  } p-2 col-6`}
                  onClick={() => handleQualification("intermediario")}
                >
                  Intermediário
                </button>
                <button
                  type="button"
                  className={`text-center btn btn-${
                    qualified === "qualificado"
                      ? "success"
                      : "outline-secondary"
                  } p-2 col-6`}
                  onClick={() => handleQualification("qualificado")}
                >
                  Qualificado
                </button>
              </div>
              <p className="card card-body">
                {qualified === "intermediario" || qualified === "qualificado"
                  ? "Investidores intermediarios e qualificados não possuem limite de aporte."
                  : "Investidores comuns podem aportar até R$ 10.000,00."}
              </p>
            </div>
          )}
          <div className="disclaimer-box">
            {project.dados.operacao === "cvm" ? (
              <p>
                Confirmo que tenho ciência que essa operação se enquadra nos
                requisitos da instrução 588 da CVM, sendo a Inco plataforma
                devidamente regulada para tal. <br />
                As sociedades empresárias de pequeno porte e as ofertas
                apresentadas nesta plataforma estão automaticamente dispensadas
                de registro pela Comissão de Valores Mobiliários - CVM.
                <br />
                <b>A CVM não analisa previamente as ofertas.</b>
              </p>
            ) : (
              <p>
                Confirmo que tenho ciência que essa operação se enquadra no
                modelo de operações de financiamento e empréstimo entre pessoas
                (P2P) por meio de plataforma eletrônica, atividade pelo Banco
                Central do Brasil, conforme dispõe a Resolução nº 4.656, de 26
                de abril de 2018.
              </p>
            )}
            <div className="form-group">
              <label className="d-flex" htmlFor="accept-check">
                <input
                  className="mr-2 mt-1"
                  type="checkbox"
                  checked={accept}
                  onChange={handleAccept}
                  id="accept-check"
                />
                Confirmo que li e concordo com as informações acima.
              </label>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4 mb-3">
          <Aside data={asideData} />
          <hr />
          <div className="warn mt-3">
            <h6>Avisos importantes</h6>
            <p>
              Sua reserva de aporte nessa rodada estará garantida com a
              assinatura digital do contrato.
            </p>
            <p>
              Após assinar o contrato, você terá dois dias uteis para pagar o
              boleto e confirmar sua operação.
            </p>
          </div>
        </div>
      </div>
      <Nav enabled={parseInt(originalValue, 10) >= 1000 && accept} />
    </div>
  );
};

export default connect(({ user_data, project, extra_user_data }) => ({
  dados: user_data.data_cliente,
  user: extra_user_data.cliente,
  project,
}))(Valor);
