/* eslint-disable */
import React from 'react';
import CardDownloadAnexo from '../cardDownloadAnexo';

export default class ContentDocumentos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const params = { name: 'juridico' };
    if (!this.state.isMounted) {
      return null;
    }

    return (
      <>
        <div className={`row ${this.props.nameContainer} ${params.name}`}>
          <div className={`col-md-12 col-xs-12 textoTab texto_${params.name}`}>
            <section>
              <article style={{ padding: 0, marginTop: '30px' }}>

                <div className="description" style={{ lineHeight: '21px' }} dangerouslySetInnerHTML={{ __html: this.props.texto }} />

                <CardDownloadAnexo data={this.props.documentos} />

              </article>
            </section>
          </div>
        </div>
      </>
    );
  }
}
