import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/browser";

import "bootstrap/dist/css/bootstrap.min.css";

Sentry.init({
  dsn:
    "https://621c8c0490f4488fa65b991d818d951b@o398456.ingest.sentry.io/5254226",
});

ReactDOM.render(<App />, document.getElementById("root"));
