/* eslint-disable */
import React, { Fragment } from "react";
import CardDownloadAnexo from "../cardDownloadAnexo";

export default class ContentViabilidade extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }
  render() {
    let params = { name: "viabilidade" };
    if (!this.state.isMounted) {
      return null;
    }
    return (
      <Fragment>
        <div className={"row " + this.props.nameContainer + " " + params.name}>
          <div className={"col-md-12 col-xs-12 textoTab texto_" + params.name}>
            <section>
              <h5>Performance histórica</h5>
              <p>
              Em 2020, a FazGame alcançou uma receita bruta que totalizou R$ 807 mil, o que representou um crescimento anual médio de 30% desde 2018, resultado da validação no mercado do serviço oferecido pela empresa em escolas da rede pública e da rede privada.
              </p>
              <p>
                A estrutura de custos da FazGame é majoritariamente variável, composta principalmente de custos de implantação da plataforma nas escolas .
              </p>
              <p>
                A margem EBITDA da empresa manteve-se estável em 2019 e 2020, com o crescimento da receita no período houve contratação de novos funcionários para complementar o time de vendas e de tecnologia. O foco em 2020 foi estruturar a metodologia pedagógica a ser desenvolvida nos games pelos alunos. Dessa maneira, a empresa conseguirá dar mais escalabilidade ao seu processo de produção dando fólego financeiro para investimentos na equipe de vendas em 2021.
              </p>
              <p>
                Ao longo dos últimos três anos, a FazGame apresentou sólida posição financeira e índice de liquidez corrente saudável.
              </p>

              <img
                className="d-flex mx-auto my-2"
                src="https://i.imgur.com/hA4GMc9.png"
              />
            </section>

            <section>
              <article style={{ padding: 0, marginTop: "30px" }}>
                <div
                  className="description"
                  style={{ lineHeight: "21px" }}
                  dangerouslySetInnerHTML={{ __html: this.props.texto }}
                ></div>

                <CardDownloadAnexo
                  href={`${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/viabilidade`}
                  data={this.props.viabilidade}
                />
              </article>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}
