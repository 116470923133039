import React from "react";
import MediaQuery from "react-responsive";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { ASSETS_ARQUIVOS_EMPREEDIMENTO } from "../../../helper/config";
import { moeda } from "../../../helper/numbers.helper";
import Abas from "../../../components/abas/captacaoAbas";

export default function Price({ data }) {
  return (
    <div className="container-fluid">
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${data.dados.img})`,
        }}
        className="row acompanhe-header mb-5"
      >
        {/* header mobile price */}
        <MediaQuery maxWidth={768}>
          <div className="col d-flex justify-content-center align-items-center">
            <div className="header-mobile">
              <div className="mainMobile">
                <div className="d-flex flex-column">
                  <small>Valor investido</small>
                  <h2 className="font-weight-bold">
                    {moeda(data.investimento.valor_investimento)}
                  </h2>
                </div>
                <div className="d-flex py-2">
                  <div className="col px-0">
                    <small>Valor recebido</small>
                    <h4 className="font-weight-bolder">
                      {moeda(data.investimento.pagamentos.total_recebido)}
                    </h4>
                  </div>
                  <div className="col px-0">
                    <small>Parcelas pagas</small>
                    <h4 className="font-weight-bolder">
                      {`${data.investimento.pagamentos.ultima_parcela_paga}/${data.dados.tempo_retorno}`}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        {/* header desktop price */}

        <MediaQuery minWidth={769}>
          <div className="mx-auto header-desktop">
            <div className="mx-3">
              <span>Valor investido</span>
              <h2 className="font-weight-bold">
                {moeda(data.investimento.valor_investimento)}
              </h2>
            </div>
            <div className="mx-3">
              <span>Valor recebido</span>
              <h4 className="font-weight-bolder">
                {moeda(data.investimento.pagamentos.total_recebido)}
              </h4>
            </div>
            <div className="mx-3">
              <span>Parcelas pagas</span>
              <h4 className="font-weight-bolder">
                {`${data.investimento.pagamentos.ultima_parcela_paga}/${data.dados.tempo_retorno}`}
              </h4>
            </div>
          </div>
        </MediaQuery>
      </div>
      <div className="acompanhe-content">
        <MediaQuery maxDeviceWidth={768}>
          <div className="row content-mobile">
            <div className="py-3 col-12 d-flex">
              <span>{`Rentabilidade: ${data.investimento.taxa_anual}`}</span>
            </div>
            <div className="secondary-background py-3 col-12 d-flex">
              <span>{`Duração: ${data.dados.tempo_retorno} meses`}</span>
            </div>
            <div className="secondary-background py-3 col-12 d-flex">
              <span>Modalidade: Price</span>
            </div>
            {data.dados.pdf_ajuda_titulo ? (
              <div className="py-3 col-12 d-flex">
                <a
                  className="d-flex align-items-center"
                  href={`${ASSETS_ARQUIVOS_EMPREEDIMENTO}/${data.dados.pdf_ajuda_arquivo}`}
                >
                  <FaCloudDownloadAlt size="1.5rem" />
                  <span className="ml-2">{data.dados.pdf_ajuda_titulo}</span>
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </MediaQuery>
        <MediaQuery minWidth={769}>
          <div className="container content-desktop">
            <div className="row">
              <div className="col">
                <span>{data.categoria}</span>
                <h5 className="font-weight-bold">{data.dados.nome}</h5>
              </div>
            </div>
            <div className="row extra-content-desktop py-5">
              <div className="col-6">
                <span>{`Rentabilidade: ${data.investimento.taxa_anual}`}</span>
              </div>
              <div className="col-6">
                <span>{`Duração: ${data.dados.tempo_retorno} meses`}</span>
              </div>
              <div className="col-6">
                <span>{`Investimento mínimo: ${data.dados.investimento_minimo}`}</span>
              </div>
              <div className="col-6">
                <span>Modalidade: Price</span>
              </div>
            </div>
          </div>
        </MediaQuery>
        <div className="tabs py-3">
          <Abas {...data} acompanhe />
        </div>
      </div>
    </div>
  );
}
