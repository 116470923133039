/* eslint-disable */
import React, { useState } from 'react';
import MediaQuery from 'react-responsive';
import { IoIosArrowForward } from 'react-icons/io';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { moeda } from '../helper/numbers.helper';
import PendencyModal from './modal/pendency';

const Table = ({ data, title }) => {
  const [pendency, setPendency] = useState(null);

  const handleItem = (id) => {
    if (!id) {
      return false;
    }
    return setPendency(id);
  };

  const getHash = (id) => {
    const d = new Date();
    const id_hash = (d.getTime() * id).toString().substring(7, 13) + id;
    return id_hash;
  };

  return (
    <>
      <div className="investment-table justify-content-center">
        <h3 className="col">{title}</h3>
        <div className="table">
          <MediaQuery minWidth={769}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Empreendimento</th>
                  <th scope="col">Investimento</th>
                  <th scope="col">Rentabilidade</th>
                  <th scope="col">Vidas impactadas</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map(({
                    id,
                    empreendimento,
                    valor_investimento,
                    rentabilidade,
                    status,
                    status_contrato,
                  }) => (
                    <tr key={id}>
                      <td>{empreendimento}</td>
                      <td>{moeda(valor_investimento)}</td>
                      <td>{rentabilidade}</td>
                      <td className="text-center">{parseInt(valor_investimento) / 100}</td>
                      <td>{`${parseInt(status, 10) === 4 ? 'Ok' : 'Pendente'}`}</td>
                      <td>
                        {
                          ((parseInt(status, 10) === 1
                            || parseInt(status, 10) === 3)
                            || !status_contrato)
                            ? <button type="button" onClick={() => handleItem(id)} className="btn btn-danger">Ver pendencias</button>
                            : <Link to={`/dashboard/acompanhe/${getHash(id)}`} type="button" className="btn btn-success">Acompanhar</Link>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Empreendimento</th>
                  <th scope="col">Investimento</th>
                  <MediaQuery minWidth={500}>
                    <th scope="col">Rentabilidade</th>
                  </MediaQuery>
                  <MediaQuery minWidth={600}>
                    <th scope="col">Status</th>
                  </MediaQuery>
                </tr>
              </thead>
              <tbody>
                {
                  data.map(({
                    id, empreendimento, valor_investimento, rentabilidade, status, status_contrato,
                  }) => (
                    <tr key={id}>
                      <td>{empreendimento}</td>
                      <td>{moeda(valor_investimento)}</td>
                      <MediaQuery minWidth={500}>
                        <td>{rentabilidade}</td>
                      </MediaQuery>
                      <MediaQuery minWidth={600}>
                        <td>{`${parseInt(status, 10) === 4 ? 'Ok' : 'Pendente'}`}</td>
                      </MediaQuery>
                      <td>
                        {
                          ((parseInt(status, 10) === 1
                            || parseInt(status, 10) === 3)
                            || !status_contrato)
                            ? <button type="button" id={id} onClick={() => handleItem(id)} className="btn mobile-handle"><IoIosArrowForward /></button>
                            : <Link to={`/dashboard/acompanhe/${getHash(id)}`} type="button" className="btn btn-success">Acompanhar</Link>
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </MediaQuery>
        </div>
      </div>
      {pendency
        ? <PendencyModal cb={() => setPendency(null)} pendency={pendency} />
        : ''}
    </>
  );
};
Table.defaultProps = {
  data: [],
  title: 'Ativos',
};

export default connect(() => ({}))(Table);
