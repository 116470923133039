import React, { useState } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";

import { moeda } from "../helper/numbers.helper";
import { private_postApi } from "../helper/request.helper";

import { prev_step, next_step, set_investment } from "../store/actions";

const FooterNav = ({ enabled, dados, dispatch }) => {
  const [sending, setSendind] = useState(false);

  const handleSignAfter = () => {
    toast.success(
      "Você poderá assinar este contrato pelo seu painel de investidor"
    );
    return setTimeout(() => {
      window.location.pathname = "/dashboard/meus-investimentos";
      return true;
    }, 3000);
  };

  const handleNext = async () => {
    if (dados.current_step === 2) {
      if (
        dados.extra_user_data.cliente.steps_verificacao_perfil.etapa2 ||
        parseInt(dados.extra_user_data.cliente.perfil_verificado, 10)
      ) {
        return dispatch(next_step(3));
      }
      if (dados.verify_front_document && dados.verify_selfie_document) {
        setSendind(true);
        await private_postApi("/clientes/verificar-perfil-docs-suitability", {
          etapa2: {
            suitability: {},
            foto_documento: dados.verify_front_document,
            foto_documento_verso: dados.verify_back_document,
            foto_selfie: dados.verify_selfie_document,
          },
          etapa3: dados.extra_user_data.cliente,
        })
          .then(({ data }) => {
            if (data.success) {
              toast.success("Arquivos enviados!");
              toast.warn(
                "Seu perfil está sendo verificado, assim que receber email de confirmação retorne a esse passo e conclua seu investimento",
                {
                  autoClose: 8000,
                }
              );
              setSendind(false);
              return setTimeout(() => dispatch(next_step(3)), 3000);
            }
            setSendind(false);
            return toast.error(data.msg);
          })
          .catch(() => {
            toast.error("Erro ao enviar os arquivos.");
            return setSendind(false);
          });
      }
    }
    if (dados.current_step === 3) {
      if (
        // se os termos forem aceitos, o valor de investimentos for maior que o minimo e menor ou igual as cotas disponiveis
        dados.investment.accept &&
        parseInt(dados.investment.value, 10) >=
          parseInt(dados.project.dados.investimento_minimo, 10) &&
        parseInt(dados.project.meta_alvo, 10) -
          parseInt(dados.project.arrecadado, 10) >=
          parseInt(dados.investment.value, 10)
      ) {
        setSendind(true);
        return dispatch(next_step(dados.current_step + 1));
      }
      if (
        parseInt(dados.project.meta_alvo, 10) -
          parseInt(dados.project.arrecadado, 10) <
        parseInt(dados.investment.value, 10)
      ) {
        return toast.error(
          `O numero de cotas disponíveis permite investimento de até ${moeda(
            parseInt(dados.project.arrecadado) -
              parseInt(dados.project.meta_alvo)
          )}`
        );
      }
      return toast.error(
        `${
          dados.investment.accept
            ? `Valor mínimo de investimento é de ${moeda(
                dados.project.dados.investimento_minimo
              )}`
            : "Confirme que seu investimento não ultrapassa seu limite de investimento"
        }`
      );
    }
    if (dados.current_step === 4) {
      setSendind(true);
      return private_postApi(
        `/investir/contrato${
          dados.project.dados.canal_assinatura === "moneyp" ? "_bmp" : ""
        }`,
        {
          slug: dados.project.dados.url,
          valor_investimento: dados.investment.value,
        }
      ).then(async (response) => {
        const {
          success,
          id_investimento,
          msg,
          key,
          request_signature_key,
        } = response.data;
        if (success) {
          const newState = { ...dados.investment };
          if (request_signature_key) {
            newState.contract = {
              id: id_investimento,
              key,
              request_signature_key,
            };
          } else if (!request_signature_key && id_investimento) {
            newState.id = id_investimento;
          }
          await dispatch(set_investment(newState));
          toast.success(msg);
          return setTimeout(() => {
            dispatch(next_step(dados.current_step + 1));
            setSendind(false);
          }, 3000);
        }

        setSendind(false);
        return toast.error(msg);
      });
    }
    if (dados.current_step === 5) {
      dispatch(next_step(dados.current_step + 1));
    }
    if (dados.current_step === 6) {
      dispatch(next_step(1));
    }
    return false;
  };

  const handlePrev = () => {
    if (dados.current_step >= 2 && dados.current_step < 5) {
      return dispatch(prev_step(dados.current_step - 1));
    }
    return false;
  };

  return (
    <>
      <div className="row py-5 justify-content-between justify-content-md-end">
        <ToastContainer autoclose={8000} />
        {!(dados.current_step === 6) ? (
          <div className="col-auto">
            <button
              type="button"
              onClick={handlePrev}
              className="btn btn-outline-success"
            >
              Voltar
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="col-auto">
          {dados.current_step === 5 && (
            <button
              type="button"
              onClick={handleSignAfter}
              className="mr-2 btn btn-outline-secondary"
            >
              Assinar depois
            </button>
          )}
          {!(dados.current_step === 6) ? (
            <button
              type="button"
              onClick={handleNext}
              disabled={!enabled ? true : sending}
              className="btn btn-success"
            >
              {`${!sending ? "Próximo" : "Aguarde"}`}
            </button>
          ) : (
            <Link
              to="/dashboard/meus-investimentos"
              onClick={handleNext}
              className="btn btn-success"
            >
              Finalizar
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default connect((state) => ({ dados: state }))(FooterNav);
