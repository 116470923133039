import React, { useEffect } from "react";
import Menu from "./menu";
import Whatsapp from "../../components/whatsapp";

const DashboardTemplate = ({ location, children }) => {
  return (
    <div className="dashboard">
      <Menu page={location.pathname.split("/")[2]} />
      {React.cloneElement(children)}
    </div>
  );
};

export default DashboardTemplate;
