import React, { useState } from "react";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FaBars } from "react-icons/fa";
import Inco from "../../../assets/platform/logo_branco.svg";

const Nav = ({ page, partner_data }) => {
  const [toggle, setToggle] = useState(false);

  const handleMenu = () => setToggle(!toggle);

  const headerTitle = () => {
    switch (page) {
      case "meus-investimentos":
        return "Meus investimentos";
      case "acompanhe":
        return "Acompanhe";
      case "perfil":
        return "Perfil";
      case "investir":
        return "Investir";
      default:
        return "Painel do investidor";
    }
  };

  return (
    partner_data !== null && (
      <div className="dashboard-sidebar">
        <MediaQuery maxDeviceWidth={768}>
          <div
            className={`dashboard-nav-mobile ${toggle ? "active" : ""}`}
          >
            <ul
              className="dashboard-nav-box"
            >
              <div className="logo p-3">
                <Link to="/">
                  <img src={partner_data.logotipo_painel} alt="logo" />
                </Link>
                <button
                  onClick={handleMenu}
                  type="button"
                  className="close-nav-mobile"
                >
                  x
                </button>
              </div>
              <a
                href="/dashboard/investir"
                className={`dashboard-nav-links ${
                  page === "investir" ? "active" : ""
                }`}
              >
                Investir
              </a>
              <a
                href="/dashboard/meus-investimentos"
                className={`dashboard-nav-links ${
                  page === "meus-investimentos" ? "active" : ""
                }`}
              >
                Meus investimentos
              </a>
              <a
                href="/dashboard/perfil"
                className={`dashboard-nav-links ${
                  page === "perfil" ? "active" : ""
                }`}
              >
                Perfil
              </a>
              <hr />
              <a
                href="/logout"
                className="dashboard-nav-links"
              >
                Sair
              </a>
              {partner_data.operacao_parceiro === "cvm" && (
                <div className="powered d-flex">
                  <img alt="powered by inco" src={Inco} />
                </div>
              )}
            </ul>
          </div>
          <div
            className="dashboard-header"
          >
            <button onClick={handleMenu} className="btn" type="button">
              <FaBars size={20} color="white" />
            </button>
            <h4 style={{ margin: "auto", fontWeight: 900 }}>{headerTitle()}</h4>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={769}>
          <div
            className="dashboard-nav"
          >
            <ul
              className="dashboard-nav-box"
            >
              <div className="logo p-3">
                <Link to="/">
                  <img src={partner_data.logotipo_painel} alt="logo" />
                </Link>
              </div>
              <a
                href="/dashboard/investir"
                className={`dashboard-nav-links ${
                  page === "investir" ? "active" : ""
                }`}
              >
                Investir
              </a>
              <a
                href="/dashboard/meus-investimentos"
                className={`dashboard-nav-links ${
                  page === "meus-investimentos" ? "active" : ""
                }`}
              >
                Meus investimentos
              </a>
              <a
                href="/dashboard/perfil"
                className={`dashboard-nav-links ${
                  page === "perfil" ? "active" : ""
                }`}
              >
                Perfil
              </a>
              <hr />
              <a
                href="/logout"
                className="dashboard-nav-links"
              >
                Sair
              </a>
              {partner_data.operacao_parceiro === "cvm" && (
                <div className="powered d-flex">
                  <img alt="powered by inco" src={Inco} />
                </div>
              )}
            </ul>
          </div>
        </MediaQuery>
      </div>
    )
  );
};

export default connect(({ partner_data }) => ({ partner_data }))(Nav);
