import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { getApi } from "./helper/request.helper";
import Login from "./pages/login";
import Cadastrar from "./pages/cadastrar";
import MeusInvestimentos from "./pages/dashboard/meusInvestimentos";
import Perfil from "./pages/dashboard/perfil";
import Acompanhe from "./pages/dashboard/acompanhe";
import Verificar from "./pages/dashboard/verificar";
import DashInvestir from "./pages/dashboard/investir";
import Investir from "./pages/investir";
import Project from "./pages/project";
import NotFound from "./pages/notFound";
import Recovery from "./pages/recovery";
import OfertasEncerradas from "./pages/ofertas-encerradas";
import Home from "./pages/home";
import { connect } from "react-redux";
import { BASE_URL } from "./helper/config";
import GlobalStyle from "./styles";
import { set_partner_data } from "./store/actions";
import Assinar_Documento from "./pages/assinarDoc";

import DashboardTemplate from "./templates/dashboard";

import { isAuthenticated, Logout } from "./auth";

function Router({ dispatch, partner }) {
  useEffect(() => {
    async function requestPartnerInfo() {
      if (partner === null) {
        const response = await getApi(
          `${BASE_URL}/api/whitelabel/v1/services/configx`
        )
          .then(({ data }) => {
            if (data.success) {
              return dispatch(set_partner_data(data.data));
            } else if (data.error) {
              return toast.error("Erro ao carregar informações do parceiro");
            }
          })
          .catch((err) => {
            toast.error("Erro ao carregar informações do parceiro");
          });
        return response;
      }
      return false;
    }
    requestPartnerInfo();

    return () => true;
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      onUpdate={() => window.scrollTo(0, 0)}
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <DashboardTemplate {...props}>
            <Component {...props} />
          </DashboardTemplate>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  return partner ? (
    <BrowserRouter>
      <ToastContainer autoclose={8000} />
      <GlobalStyle theme={partner} />

      <Switch>
        <Route path="/cadastrar" exact component={Cadastrar} />
        <Route path="/login" exact component={Login} />
        <Route path="/ofertas-encerradas" exact component={OfertasEncerradas} />
        <Route path="/recovery/senha/:code?" exact component={Recovery} />
        <Route path="/" exact component={Home} />
        <Route path="/logout" exact component={Logout} />

        <Route
          path="/assinar_documento/:slug"
          exact={true}
          component={Assinar_Documento}
          page={{
            title: "Assinar documentos",
            description: "Invista em seu futuro",
          }}
        />
        <PrivateRoute
          path="/dashboard/investir"
          exact
          component={DashInvestir}
        />
        <PrivateRoute
          path="/dashboard/meus-investimentos"
          exact
          component={MeusInvestimentos}
        />
        <PrivateRoute path="/dashboard/perfil" exact component={Perfil} />
        <PrivateRoute
          path="/dashboard/acompanhe/:project"
          exact
          component={Acompanhe}
        />
        <PrivateRoute path="/dashboard/verificar" exact component={Verificar} />
        <Route path="/projeto/:project" exact component={Project} />
        <Route path="/investir/:project" exact component={Investir} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  ) : (
    ""
  );
}

export default connect(({ partner_data }) => ({ partner: partner_data }))(
  Router
);
