/* eslint-disable */
/**
 Index.js - Funciona como um root de funções que chama os componentes
 Informação: retorna todos componentes de tabContents do empreendimento e mantem todas
 dentro do mesmo escopo de configuração quando chamadas.
*/
import React from 'react';
import { ContentEmpresa } from './contentEmpresa';
import ContentEmpreendimento from './contentEmpreendimento';
import ContentEmpreendedor from './contentEmpreendedor';
import ContentRemuneracao from './contentRemuneracao';
import ContentInvestidores from './contentInvestidores';
import ContentViabilidade from './contentViabilidade';
import ContentJuridico from './contentJuridico';
import ContentVendas from './contentVendas';
import ContentAvancoObra from './contentAvancoObra';
import ContentOperacao from './contentOperacao';
import ContentDocumentos from './contentDocumentos';
import ContentRelatorios from './contentRelatorios';
import ContentFotosObra from './contentFotosObra';
import ContentForum from './contentForum';
import ContentPagamento from './ContentPagamento';

import './tab-content.css';

const nameContainer = 'containerTabContent';

// ############################################ ABAS DE DETALHES
export const _abaEmpresa = (props) => (
  ContentEmpresa({ nameContainer, ...props })
);

// ############################################
export const _abaEmpreendimento = (props) => (
  <ContentEmpreendimento {... props} nameContainer={nameContainer} />
);
// ############################################
export const _abaEmpreendedor = (props) => (
  <ContentEmpreendedor {... props} nameContainer={nameContainer} />
);
// ############################################
export const _abaRemuneracao = (props) => (
  <ContentRemuneracao {... props} nameContainer={nameContainer} />
);
// ############################################
export const _abaInvestidores = (props) => (
  <ContentInvestidores {... props} nameContainer={nameContainer} />
);

// ############################################
export const _abaViabilidade = (props) => (
  <ContentViabilidade {... props} nameContainer={nameContainer} />
);
// ############################################
export const _abaJuridico = (props) => (
  <ContentJuridico {... props} nameContainer={nameContainer} />
);

// ############################################
export const _abaVendas = (props) => (
  <ContentVendas {... props} nameContainer={nameContainer} />
);

// ############################################
export const _abaAvancoObra = (props) => (
  <ContentAvancoObra {... props} nameContainer={nameContainer} />
);

// ############################################
export const _abaOperacao = (props) => (
  <ContentOperacao {... props} nameContainer={nameContainer} />
);

// ############################################ ABAS DE ACOMPANHE

export const _abaDocumentos = (props) => (
  <ContentDocumentos {... props} nameContainer={nameContainer} />
);

export const _abaRelatorios = (props) => (
  <ContentRelatorios {... props} nameContainer={nameContainer} />
);

export const _abaFotosObra = (props) => (
  <ContentFotosObra {... props} nameContainer={nameContainer} />
);

export const _abaForum = (props) => (
  <ContentForum {... props} nameContainer={nameContainer} />
);

export const _abaPagamentos = (props) => (
  <ContentPagamento {... props} nameContainer={nameContainer} />
);
