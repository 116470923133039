import React from "react";
import INCO from "../assets/platform/logo_branco.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { isAuthenticated } from "../auth";

const Header = ({ menu, partner_data, project }) =>
  partner_data !== null && (
    <>
      <Navbar
        expand="md"
        style={{
          backgroundColor: partner_data.cor_secundaria,
        }}
        variant="light"
      >
        <div className="container">
          <Navbar.Brand href={project ? `/projeto/${project}` : "/"}>
            <div className="row">
              {partner_data.operacao_parceiro === "cvm" && (
                <img
                  alt="Powered by INCO"
                  src={INCO}
                  width="240"
                  height="96"
                  className="d-inline-block ml-3 align-top header-logo"
                />
              )}
              <img
                alt=""
                src={partner_data.logotipo_site}
                width="240"
                className="d-inline-block align-top header-logo"
              />
            </div>
          </Navbar.Brand>
          {menu && (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse
                className="justify-content-end"
                id="basic-navbar-nav"
              >
                <Nav className="d-flex align-items-center">
                  {isAuthenticated() ? (
                    <NavDropdown title="Minha conta" id="basic-nav-dropdown">
                      <NavDropdown.Item href="/dashboard/meus-investimentos">
                        Meus investimentos
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/dashboard/perfil">
                        Meu perfil
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="/logout">Sair</NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <Nav.Item>
                      <Link
                        style={{
                          backgroundColor: partner_data.cor_primaria,
                          border: "none",
                        }}
                        className="btn btn-success mx-2"
                        to="/cadastrar"
                      >
                        Criar conta
                      </Link>
                      <Link
                        className="btn btn-outline-success mx-2"
                        to="/login"
                      >
                        Entrar
                      </Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </div>
      </Navbar>
    </>
  );

export default connect(({ user_data, partner_data }) => ({
  dados: user_data,
  partner_data,
}))(Header);
