export const set_partner_data = (data) => ({
  type: "SET_PARTNER_DATA",
  payload: data,
});

export const set_user_data = (data) => ({
  type: "SET_USER_DATA",
  payload: data,
});

export const set_extra_user_data = (data) => ({
  type: "SET_EXTRA_USER_DATA",
  payload: data,
});

export const set_current_step = (step) => ({
  type: "SET_CURRENT_STEP",
  payload: step,
});
export const submit_form_extra_data = (data) => ({
  type: "SUBMIT_FORM_EXTRA_DATA",
  payload: data,
});

export const submit_verify_front = (data) => ({
  type: "SET_VERIFY_FRONT",
  payload: data,
});
export const submit_verify_back = (data) => ({
  type: "SET_VERIFY_BACK",
  payload: data,
});
export const submit_verify_selfie = (data) => ({
  type: "SET_VERIFY_SELFIE",
  payload: data,
});

export const submit_verify_suitability = (data) => ({
  type: "SET_VERIFY_SUITALIBITY",
  payload: data,
});

export const submit_verify_basic = (data) => ({
  type: "SET_VERIFY_BASIC",
  payload: data,
});

export const submit_verify_docs = (data) => ({
  type: "SET_VERIFY_DOCS",
  payload: data,
});

export const submit_verify_extra = (data) => ({
  typé: "SET_VERIFY_EXTRA",
  payload: data,
});

export const next_step = (newStep) => ({
  type: "NEXT_STEP",
  payload: newStep,
});

export const prev_step = (newStep) => ({
  type: "PREV_STEP",
  payload: newStep,
});

export const load_project = (data) => ({
  type: "LOAD_PROJECT",
  payload: data,
});

export const set_investment = (data) => ({
  type: "SET_INVESTMENT",
  payload: data,
});

export const assign_oav = (data) => ({
  type: "ASSIGN_OAV",
  payload: data,
});

export const assign_cvm = (data) => ({
  type: "ASSIGN_CVM",
  payload: data,
});

export const set_investments = (data) => ({
  type: "SET_INVESTIMENTS",
  payload: data,
});

export const set_pendency = (id) => ({
  type: "SET_PENDENCY",
  payload: id,
});

export const reset_investment = () => ({
  type: "RESET_INVESTIMENT_PROGRESS",
  payload: null,
});

export const set_userIRHash = (hash) => ({
  type: "SET_USER_IR",
  payload: hash
})
