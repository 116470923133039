import React, { useState } from "react";
import { connect } from "react-redux";
import Aside from "../../components/aside";
import Nav from "../../components/footerNav";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
const Termo = ({ dados, match }) => {
  const [accept, setAccept] = useState(false);

  const asideData = {
    name: dados.nome,
    deadline: dados.tempo_retorno,
    minimum_investment: dados.investimento_minimo,
    profit: dados.rentabilidade,
  };

  const handleAccept = () => {
    return setAccept(!accept);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-10">
          <h3>Termo de risco.</h3>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Link className="btn" to={`/projeto/${match.params.project}`}>
            <AiOutlineClose size="24px" />
          </Link>
        </div>
        <div className="col col-sm-8">
          <div
            className="termo_box mb-3"
            dangerouslySetInnerHTML={{ __html: dados.termo_risco_preview }}
          />
          <div className="termo_accept mt-3">
            <label htmlFor="accept">
              <input
                id="accept"
                onChange={handleAccept}
                value={accept}
                type="checkbox"
                defaultChecked={false}
              />
              <span className="ml-3">
                <b>Declaro que li e aceito</b> os termos desse investimento.
              </span>
            </label>
          </div>
        </div>
        <div className="d-none d-sm-block col-4">
          <Aside data={asideData} />
          <hr />
          <div className="warn mt-3">
            <h6>Avisos importantes</h6>
            <p>
              Sua reserva de investimento nessa rodada estará garantida com a
              assinatura digital do contrato.
            </p>
            <p>
              Após assinar o contrato, você terá dois dias uteis para pagar o
              boleto e confirmar seu investimento.
            </p>
          </div>
        </div>
      </div>
      <Nav enabled={accept} />
    </div>
  );
};

export default connect(({ project }) => ({ dados: project.dados }))(Termo);
