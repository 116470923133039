import { createStore } from "redux";

const INITIAL_STATE = {
  current_step: 0,
  investimentos: {
    saldo: null,
    rentabilidade: null,
    investimentos: [],
  },
  userIrHash: '',
  verify_front_document: null,
  verify_back_document: null,
  verify_selfie_document: null,
  verify_suitability_document: null,
  user_data: null,
  extra_user_data: null,
  powered_data: null,
  investment: null,
  project: null,
  pendency: null,
  partner_data: null,
  verify: {
    basic: {},
    docs: {},
    extra: {},
    step: 1,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case "RESET_INVESTIMENT_PROGRESS":
      return { ...state, current_step: 0 };
    case "SET_PARTNER_DATA":
      return { ...state, partner_data: action.payload };
    case "SET_USER_DATA":
      localStorage.setItem("session", JSON.stringify(action.payload));
      return { ...state, user_data: action.payload };
    case "SET_CURRENT_STEP":
      return { ...state, current_step: action.payload };
    case "SET_EXTRA_USER_DATA":
      return { ...state, extra_user_data: action.payload };
    case "SUBMIT_FORM_EXTRA_DATA":
      newState = { ...state.extra_user_data };
      newState.cliente = action.payload;
      return {
        ...state,
        extra_user_data: newState,
        current_step: action.payload.perfil_verificado ? 3 : 2,
      };
    case "SET_VERIFY_BASIC":
      newState = { ...state.verify };
      let newUserData = { ...state.extra_user_data };
      newUserData.cliente = action.payload;
      newState.basic = action.payload;
      newState.step = 2;
      return { ...state, extra_user_data: newUserData, verify: newState };
    case "SET_VERIFY_DOCS":
      newState = { ...state.verify };
      newState.docs = action.payload;
      newState.step = 3;
      return { ...state, verify: newState };
    case "SET_VERIFY_EXTRA":
      newState = { ...state.verify };
      newState.extra = action.payload;
      return { ...state, verify: newState };
    case "NEXT_STEP":
      return { ...state, current_step: action.payload };
    case "PREV_STEP":
      return { ...state, current_step: action.payload };
    case "SET_VERIFY_FRONT":
      return { ...state, verify_front_document: action.payload };
    case "SET_VERIFY_BACK":
      return { ...state, verify_back_document: action.payload };
    case "SET_VERIFY_SELFIE":
      return { ...state, verify_selfie_document: action.payload };
    case "SET_VERIFY_SUITALIBITY":
      return { ...state, verify_suitability_document: action.payload };
    case "LOAD_PROJECT":
      return { ...state, project: action.payload };
    case "SET_INVESTMENT":
      return { ...state, investment: action.payload };
    case "ASSIGN_OAV":
      return { ...state, investment: action.payload, current_step: 6 };
    case "ASSIGN_CVM":
      return { ...state, investment: action.payload, current_step: 6 };
    case "SET_INVESTIMENTS":
      return { ...state, investimentos: action.payload };
    case "SET_PENDENCY":
      return { ...state, pendency: action.payload };
    case "SET_USER_IR":
      return { ...state, userIrHash: action.payload };
  
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;
