import React, { useState, useEffect } from "react";
import { getApi } from "../helper/request.helper";
import { Helmet } from "react-helmet";
import Abas from "../components/abas/captacaoAbas";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Footer from "../components/footer";
import Header from "../components/header";
import Card from "../components/cardProject";

const Project = (props) => {
  const [data, setData] = useState(null);
  const [active, setActive] = useState(0);

  const handleChangeActive = (e) => {
    const { value } = e.target;
    return setActive(parseInt(value));
  };

  const getProject = async () => {
    if (props.match.params.project) {
      return getApi(`/empreendimento/${props.match.params.project}`).then(
        async (response) => {
          if (response.data.success) {
            return setData(response.data.data);
          }
          return toast.error(response.data.msg);
        }
      );
    }
    window.location.pathname = "/";
    return false;
  };
  useEffect(() => {
    getProject();

    return () => setData(null);
  }, []);

  return (
    props.partner_data !== null && (
      <>
        {data ? (
          <>
            <Helmet>
              <title>{`Investir em ${data.dados.nome}`}</title>
            </Helmet>
            <Header menu />
            <div className="container">
              <main>
                <Card
                  active={active}
                  handleChangeActive={handleChangeActive}
                  data={data}
                  theme={props.partner_data}
                />
                <div className="row my-5">
                  <Abas {...data} />
                </div>
              </main>
            </div>
            <Footer />
          </>
        ) : (
          <div className="container d-flex py-5 justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default connect(({ partner_data }) => ({ partner_data }))(Project);
