/* eslint-disable */
import React, { Fragment } from "react";
import { BASE_URL } from "../../../helper/config";

export const ContentEmpresa = (props) => {
  let params = { name: "empresa" };
  return (
    <Fragment>
      <div className={"row " + props.nameContainer + " " + params.name}>
        <div className={"col-md-6 col-xs-12 logo_" + params.name}>
          <img src={`${BASE_URL}/assets/arquivos/empresas/${props.logo}`} />
        </div>
        <div className={"col-md-6 col-xs-12 textoTab texto_" + params.name}>
          <section>
            <h1>Empresa captadora</h1>
            <article
              dangerouslySetInnerHTML={{ __html: props.texto }}
            ></article>
          </section>
        </div>
      </div>
    </Fragment>
  );
};
