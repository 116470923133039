import React, { useState, useEffect } from "react";
import Header from "../components/header";
import { postApi, getApi } from "../helper/request.helper";

import { toast, ToastContainer } from "react-toastify";

function Recovery({ match }) {
  const [page, setPage] = useState(1);
  const [buttonTitle, setButtonTitle] = useState("Enviar");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmpass, setConfirmPass] = useState("");
  const [verified, setVerified] = useState(null);

  useEffect(() => {
    const verifyCode = (code) => {
      getApi(`/services/vcr/${code}`).then(({ data }) => {
        if (data.success) {
          return setVerified(true);
        } else {
          return setVerified(false);
        }
      });
    };

    if (match.params.code) {
      return verifyCode(match.params.code);
    } else {
      return false;
    }
  }, []);

  const handleSendCode = () => {
    if (!email) {
      return false;
    }
    setButtonTitle("Enviando...");
    postApi("/services/send-recovery", { email }).then(async (response) => {
      if (response.data.success) {
        toast.success(`Email enviado para ${email}`);
        await setButtonTitle("Enviar");
        return setPage(2);
      } else {
        toast.error(`Ocorreu um erro ao tentar recuperar ${email}`);
      }
    });
  };

  const changePass = () => {
    if (!email || !pass || !confirmpass) {
      return toast.error("Preencha todos os campos");
    }
    if (pass !== confirmpass) {
      return toast.error("As senhas não são iguais");
    }
    setButtonTitle("Enviando...");

    return postApi("/services/asc", {
      codigo: match.params.code,
      email: email,
      senha: pass,
      senha_verificacao: confirmpass,
    }).then(({ data }) => {
      if (data.success) {
        toast.success(data.msg);
        setTimeout(() => {
          window.location.pathname = "/login";
        }, 3000);
      } else {
        setButtonTitle("Enviar");
        return toast.error(data.msg);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return handleSendCode();
  };

  const handleChangePass = (e) => {
    e.preventDefault();
    return changePass();
  };

  const handleEmailChange = (e) => {
    return setEmail(e.target.value);
  };

  const handlePassChange = (e) => {
    return e.target.name === "pass"
      ? setPass(e.target.value)
      : setConfirmPass(e.target.value);
  };

  return (
    <>
      <Header />
      <ToastContainer autoclose={8000} />
      <div className="container">
        <div className="my-5">
          <h1>Recuperar senha</h1>
        </div>
        {!match.params.code ? (
          <div className="col-12 col-sm-6">
            <label className="col" htmlFor="email">
              Digite seu email cadastrado
            </label>
            <div className="input-group">
              <form className="form d-flex" onSubmit={(e) => handleSubmit(e)}>
                <input
                  id="email"
                  placeholder="Email"
                  className="form-control"
                  type="email"
                  onChange={(e) => handleEmailChange(e)}
                />
                <button
                  onClick={(e) => handleSubmit(e)}
                  className="btn mx-2 btn-success"
                  type="submit"
                >
                  {buttonTitle}
                </button>
              </form>
            </div>
          </div>
        ) : typeof verified !== "object" ? (
          <div className="col-12 col-sm-6">
            {verified ? (
              <form onSubmit={(e) => handleChangePass(e)}>
                <input
                  className="form-control my-2"
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={(e) => {
                    handleEmailChange(e);
                  }}
                  value={email}
                />
                <input
                  className="form-control my-2"
                  placeholder="Nova senha"
                  name="pass"
                  type="password"
                  onChange={(e) => {
                    handlePassChange(e);
                  }}
                  value={pass}
                />
                <input
                  className="form-control my-2"
                  placeholder="Confirme nova senha"
                  name="confirmpass"
                  type="password"
                  onChange={(e) => {
                    handlePassChange(e);
                  }}
                  value={confirmpass}
                />
                <button type="submit" className="btn btn-success my-2">
                  {buttonTitle}
                </button>
              </form>
            ) : (
              <span> Código invalido.</span>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Carregando...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Recovery;
