import React, { Fragment } from "react";
import {
  private_GetAPI,
  private_PutAPI,
  private_PostAPI,
  private_getMoneyp,
  private_postMoneyp,
} from "../../helper/request.helper";
import MediaQuery from "react-responsive";
import Header from '../../components/header';
import "./style.css";

import { moeda } from "../../helper/numbers.helper";


export default class Assinar_Documento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content_loaded: false,
      checked: false,
      token: "",
      dados: {},
      assinado: false,
    };
    this.handleCheck = this.handleCheck.bind(this);
    this.handleChangeToken = this.handleChangeToken.bind(this);
    this.handleAssinar = this.handleAssinar.bind(this);
    this.reenviarToken = this.reenviarToken.bind(this);
  }

  handleCheck(e) {
    this.setState({ checked: e.target.checked });
  }

  handleChangeToken(e) {
    this.setState({ token: e.target.value });
  }

  handleAssinar(e) {
    private_postMoneyp("/realizar_assinatura_tomador", {
      hash: this.props.match.params.slug,
      token_email: true,
      token: this.state.token,
    }).then((dados) => {
      if (dados.data.success) {
        this.setState({ assinado: true });
      } else if (dados.data.error) {
        this.setState({ msg: dados.data.msg });
      }
    });
  }

  reenviarToken() {
    private_getMoneyp(
      `/sendTokenEmailTomador?hash=${this.props.match.params.slug}`
    ).then((reponse) => {});
  }

  componentDidMount() {
    private_getMoneyp(`/assinatura_lote/${this.props.match.params.slug}`).then(
      (response) => {
        this.setState((state) => {
          let newState = state.dados;
          newState = response.data;
          let newStatus = state.assinado;
          if (
            response.data.signatario.data_assinatura ||
            response.data.signatario.ip_assinatura
          ) {
            newStatus = true;
          }
          return { dados: newState, assinado: newStatus, content_loaded: true };
        });
      }
    );
  }

  render() {
    if (!this.state.content_loaded) {
      return <span>Carregando...</span>;
    }

    return (
      <>
      <Header/>
        <MediaQuery maxDeviceWidth={650}>
          <div
            className={
              "container contentLoading " +
              (this.state.content_loaded ? "loaded" : "loading")
            }
          >
            <div className="col-md-12 spaceAuthWhite pageInvestir my-3 d-flex justify-content-end">
              <a href={"/"}>
                X
              </a>
            </div>

            <div className={"container-ccb"}>
              <div className={"header row"}>
                <div className="col mb-3">
                  <ul style={{ margin: "0px", listStyle: 'none' }}>
                    <li>{this.state.dados.signatario.nome}</li>
                    <li>{this.state.dados.signatario.email}</li>
                    <li>Assinante</li>
                  </ul>
                </div>
                <div className="col">
                  <div className={"empreendimento"}>
                    {this.state.dados.signatario.nome_empreendimento}
                  </div>

                  
                  <span>Assinatura de documentos em lote</span> <br></br>
                  <span
                    style={{ fontFamily: "CamptonBold", fontSize: ".8rem" }}
                  >
                    {this.state.dados.documentos.length +
                      (this.state.dados.documentos.length > 1
                        ? " documentos"
                        : " documento")}
                  </span>
                </div>
              </div>
              <div style={{ maxHeight: "260px" }} className={"body"}>
                <table class="table table-striped assinaturas-table">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Data</th>
                      <th scope="col">Documento</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dados.documentos.map((documento, key) => {
                      return (
                        <tr>
                          <th scope="row">
                            {documento.nome.replace(/(\w*\s\w)(.*)/, "$1") +
                              "."}
                          </th>
                          <td>
                            {moeda(documento.valor_investimento).replace(
                              /(R\$\s\w*).(.*)/,
                              "$1"
                            ) + "k"}
                          </td>
                          <td>{documento.data.slice(0, 5)}</td>
                          <td style={{ textAlign: "center" }}>
                            <a
                              href={`https://bmpdigital.moneyp.com.br/reportscustom/inco/imprimirccb?code=${documento.codigo_proposta}&copias=1&versao=5002003027`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Visualizar
                            </a>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {this.state.assinado ? (
                              <i class="fa fa-check"></i>
                            ) : (
                              <i class="fa fa-clock"></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={"container-token"}>
              {this.state.assinado ? (
                <div style={{ marginTop: "20px" }}>
                  <span>Todos os documentos foram assinados com sucesso!</span>
                </div>
              ) : (
                <>
                  <div className={"checkbox-token"}>
                    <div style={{ marginRight: "5px" }}>
                      <input
                        id={"checkbox-token"}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={this.handleCheck}
                      />
                    </div>
                    <label for="checkbox-token">
                      Este CCB é gerada e assinada eletronicamente, tendo a sua
                      autenticidade, integridade e validade garantidas na forma
                      da Lei. Eu reconheço como válida a assinatura eletrônica
                      dessa CCB
                    </label>
                  </div>
                  <div className={"token d-flex"}>
                    <input
                      style={{ flexGrow: "2" }}
                      className={"form-control"}
                      type="text"
                      placeholder="Token"
                      disabled={this.state.checked ? false : true}
                      onChange={this.handleChangeToken}
                    />
                    <button
                      
                      className={"btn btn-success ml-2"}
                      disabled={this.state.checked ? false : true}
                      onClick={this.handleAssinar}
                    >
                      Assinar
                    </button>
                  </div>
                  <div className={"sendToken"}>
                    <a
                      style={{ color: "#102df2" }}
                      onClick={this.reenviarToken}
                    >
                      Solicitar Token
                    </a>
                    <small
                      style={{
                        fontFamily: "CamptonLight",
                        fontWeight: "100",
                        marginTop: "10px",
                        fontSize: ".8rem",
                      }}
                    >
                      O código Token pode levar até 1 minuto para chegar em seu
                      e-mail
                    </small>
                  </div>
                </>
              )}
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={651}>
          <div
            className={
              "container contentLoading " +
              (this.state.content_loaded ? "loaded" : "loading")
            }
          >
            <div className="col-md-12 spaceAuthWhite pageInvestir my-3 d-flex justify-content-end">
              <a href={"/"}>
                X
              </a>
            </div>

            <div className={"container-ccb"}>
              <div className={"header row"}>
                <div className="col">
                  <div className={"empreendimento"}>
                    {this.state.dados.signatario.nome_empreendimento}
                  </div>
                  <span>Assinatura de documentos em lote</span> <br></br>
                  <span
                    style={{ fontFamily: "CamptonBold", fontSize: ".8rem" }}
                  >
                    {this.state.dados.documentos.length +
                      (this.state.dados.documentos.length > 1
                        ? " documentos"
                        : " documento")}
                  </span>
                </div>
                <div className="col text-right">
                  <ul style={{ margin: "0px", listStyle: 'none' }}>
                    <li>{this.state.dados.signatario.nome}</li>
                    <li>{this.state.dados.signatario.email}</li>
                    <li>Assinante</li>
                  </ul>
                </div>
              </div>
              <div className={"body"}>
                <table class="table table-striped assinaturas-table">
                  <thead>
                    <tr>
                      <th scope="col">Nome</th>
                      <th scope="col">Valor</th>
                      <th scope="col">Data</th>
                      <th scope="col">Documento</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.dados.documentos.map((documento, key) => {
                      return (
                        <tr>
                          <th scope="row">{documento.nome}</th>
                          <td>{moeda(documento.valor_investimento)}</td>
                          <td>{documento.data.slice(0, 10)}</td>
                          <td>
                            <a
                              href={`https://bmpdigital.moneyp.com.br/reportscustom/inco/imprimirccb?code=${documento.codigo_proposta}&copias=1&versao=5002003027`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Visualizar
                            </a>
                          </td>
                          <td>
                            {this.state.assinado ? "Assinado" : "Aguardando"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={"container-token"}>
              {this.state.assinado ? (
                <div style={{ marginTop: "20px" }}>
                  <span>Todos os documentos foram assinados com sucesso!</span>
                </div>
              ) : (
                <>
                  <div className={"checkbox-token"}>
                    <div style={{ marginRight: "5px" }}>
                      <input
                        id={"checkbox-token"}
                        type="checkbox"
                        checked={this.state.checked}
                        onChange={this.handleCheck}
                      />
                    </div>
                    <label for="checkbox-token">
                      Este CCB é gerada e assinada eletronicamente, tendo a sua
                      autenticidade, integridade e validade garantidas na forma
                      da Lei. Eu reconheço como válida a assinatura eletrônica
                      dessa CCB
                    </label>
                  </div>
                  <div className={"token d-flex"}>
                    <input
                      style={{ flexGrow: "2" }}
                      className={"form-control"}
                      type="text"
                      placeholder="Token"
                      disabled={this.state.checked ? false : true}
                      onChange={this.handleChangeToken}
                    />
                    <button
                      className={"ml-2 btn btn-success"}
                      disabled={this.state.checked ? false : true}
                      onClick={this.handleAssinar}
                    >
                      Assinar
                    </button>
                  </div>
                  <div className={"sendToken mt-2"}>
                    <a
                      style={{ color: "#102df2" }}
                      onClick={this.reenviarToken}
                    >
                      Solicitar Token
                    </a>
                    <small
                      style={{
                        fontFamily: "CamptonLight",
                        fontWeight: "100",
                        marginTop: "10px",
                        fontSize: ".8rem",
                      }}
                    >
                      O código Token pode levar até 1 minuto para chegar em seu
                      e-mail
                    </small>
                  </div>
                </>
              )}
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}
