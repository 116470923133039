import React from 'react';

import { Line } from 'react-chartjs-2';

export default function Chart({ data }) {
  const options = {
    responsive: true,
    showAllTooltips: true,
    tooltips: {
      enabled: true,
      callbacks: {
        label(t, d) {
          const xLabel = d.datasets[t.datasetIndex].label;
          const yLabel = t.yLabel >= 1000 ? `R$${t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : `R$${t.yLabel}`;
          return `${xLabel} - ${yLabel}`;
        },
      },
      showAllTooltips: true,
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [{
        display: false,
      }],
    },
    title: {
      display: false,
      text: '',
    },
  };

  return (
    <Line width={400} height={100} options={options} data={data} />
  );
}
