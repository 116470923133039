import axios from "axios";

import base64 from "base-64";

import {
  TOKEN_SECRET_API_INCO,
  TOKEN_SECRET_REFRESH_API_INCO,
  URI_ENDPOINT_INCO,
  URL_SYS,
} from "./config";

function procKeys() {
  const cache = JSON.parse(localStorage.getItem("session"));
  if (cache) {
    const keySecret = base64.encode(
      `${cache.key_actived}:${cache.key_refresh}`
    );
    return keySecret;
  }
}

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.pathname = "/logout";
    }
  }
);

const keySecret = base64.encode(
  `${TOKEN_SECRET_API_INCO}:${TOKEN_SECRET_REFRESH_API_INCO}`
);

export const get = (url = "/") =>
  axios({
    url,
    headers: {}
  });

export const post = (url = "/", data) =>
  axios({
    url,
    method: "post",
    data,
  });

export const getApi = (url = "/") =>
  axios({
    baseURL: URI_ENDPOINT_INCO,
    method: "get",
    url,
    headers: {
      Authorization: `Basic ${keySecret}`,
    },
  });

export const postApi = (url = "/", data) =>
  axios({
    baseURL: URI_ENDPOINT_INCO,
    url,
    method: "post",
    headers: { Authorization: `Basic ${keySecret}` },
    data,
  });

export const private_getApi = (url = "/") =>
  axios({
    baseURL: URI_ENDPOINT_INCO,
    url,
    method: "get",
    headers: { Authorization: `Private ${procKeys()}` },
  });

export const private_postApi = (url = "/", data) =>
  axios({
    baseURL: URI_ENDPOINT_INCO,
    url,
    method: "post",
    headers: { Authorization: `Private ${procKeys()}` },
    data,
  });

export const private_getMoneyp = (url = "/") =>
  axios({
    baseURL: `${URL_SYS}/moneyp`,
    url,
    method: "get",
    headers: { Authorization: `Private ${procKeys()}` },
  });

export const private_postMoneyp = (url = "/", data) =>
  axios({
    baseURL: `${URL_SYS}/moneyp`,
    url,
    method: "post",
    headers: { Authorization: `Private ${procKeys()}` },
    data,
  });
