/* eslint-disable */
import React from "react";
import ImageGallery from "react-image-gallery";

import "./tab-content.css";

export default class ContentEmpreendedor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
      showIndex: false,
      showBullets: true,
      infinite: true,
      showThumbnails: false,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: true,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 450,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: "bottom",
      showVideo: {},
    };
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !this.state.showVideo[url];
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {this.state.showVideo[item.embedUrl] ? (
          <>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <div className="video-wrapper">
              <a
                className="close-video"
                onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
              />

              <iframe
                width="560"
                height="315"
                style={{ zIndex: "9999" }}
                src={`${item.embedUrl.replace(
                  "watch?v=",
                  "embed/"
                )}?controls=1&modestbranding=1&autoplay=1&rel=0&color=red&hd=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button">
              <i className="fa fa-play" />
            </div>
            <img src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const params = { name: "empreendedor" };
    if (!this.state.isMounted) {
      return null;
    }

    const Itens = [];
    this.props.empreendedor.map((val, key) => {
      const img =
        val.tipo === "video"
          ? val.img
          : `${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/galeria/${val.img}`;
      if (val.tipo === "video") {
        Itens.push({
          original: img,
          thumbnail: img,
          embedUrl: val.tipo === "video" ? val.link : "",
          renderItem: this._renderVideo.bind(this),
        });
      } else {
        Itens.push({
          original: img,
          thumbnail: img,
        });
      }
    });

    return (
      <>
        <div
          className={`d-flex flex-column ${this.props.nameContainer} ${params.name}`}
        >
          <div className="col-xs-12">
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              showNav={false}
              lazyLoad
              showThumbnails={this.props.empreendedor.length !== 1}
              items={Itens}
            />
          </div>
          <div className={`col-xs-12 textoTab texto_${params.name}`}>
            <section>
              <article
                style={{ paddingTop: 0, marginTop: "30px" }}
                dangerouslySetInnerHTML={{ __html: this.props.texto }}
              />
            </section>
          </div>
        </div>
      </>
    );
  }
}
