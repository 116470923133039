import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { toast, ToastContainer } from "react-toastify";
import Clicksign from "../lib/clicksign";

import Nav from "./footerNav";

import { private_postApi } from "../helper/request.helper";

import { assign_cvm } from "../store/actions";

const CVM = ({ investment, dispatch }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (mounted) {
    // Carrega o widget embedded com a request_signature_key
    const widget = new Clicksign(investment.contract.request_signature_key);

    // Define o endpoint https://sandbox.clicksign.com ou https://app.clicksign.com
    widget.endpoint = "https://app.clicksign.com";

    // Define a URL de origem (parametro necessário para utilizar através de WebView)
    widget.origin = `https://${window.location.host}`;

    // Monta o widget no div
    widget.mount("contrato_cvm");

    // Callback que será disparado quando o documento for assinado
    widget.on("signed", async () => {
      private_postApi("/investir/update_assinatura", {
        i: investment.contract.id,
      });
      toast.success("Contrato assinado com sucesso!");
      const data = { ...investment };
      data.assigned = true;
      dispatch(assign_cvm(data));
      return widget.unmount();
    });
  }

  return (
    <>
      <ToastContainer autoclose={8000} />
      <div id="contrato_cvm" />
      {investment.assigned ? (
        <div className="col">
          <h4>Contrato assinado.</h4>
          <span>
            Agora sua reserva de investimento está garantida. Você receberá uma
            cópia do contrato em seu email.
          </span>
        </div>
      ) : (
        ""
      )}
      <Nav enabled={investment.assigned} />
    </>
  );
};

export default connect(({ investment }) => ({ investment }))(CVM);
