import React from "react";
import { connect } from "react-redux";
import CVM from "../assets/platform/cvm.png";
import INCO from "../assets/platform/logo_preto.svg";
import { Link } from "react-router-dom";
import { invert } from "polished";

const Footer = ({ partner_data }) => {
  return (
    <footer
      style={{
        backgroundColor: partner_data.cor_primaria,
        borderTop: "1px solid grey",
        color: "#fff",
      }}
      className="footer  py-5 d-flex"
    >
      <div className="container">
        {!(partner_data.operacao_parceiro === "cvm") ? (
          <>
            <div style={{}} className="mb-3">
              <p className="text-justify">
                A Yunus Negócios Sociais é uma plataforma que viabiliza crédito
                para negócios sociais. Essa operação faz parte do programa
                Powered By INCO, em que a INCO fornece sua tecnologia e
                regulação jurídica para parceiros captarem recursos por meio de
                P2P Lending fora do ambiente da plataforma INCO.
              </p>
              <p>
                <b>
                  A INCO não faz análise de risco nessas operações, que não
                  compõem o portfólio da plataforma INCO.
                </b>
              </p>
              <p>
                <b>
                  Leia com atenção o relatório de crédito e o termo de risco da
                  operação antes de investir.
                </b>
              </p>
            </div>
            <div style={{}}>
              <p className="text-justify">
                A INCO não é uma instituição financeira e não realiza operações
                de crédito diretamente. Atuamos como correspondente bancário nos
                termos do art. 2º, da Resolução CMN 3.954, de 24 de fevereiro de
                2011, a fim de conectar investidores interessados em recebíveis
                vinculados à operações de crédito, focado em projetos de
                mercados base da economia real, como: mercado imobiliário,
                indústria, energia, mobilidade, telecomunicações, dentre outros.
                O modelo OAV é regulado pelo Banco Central do Brasil. Somos
                correspondentes bancários das seguintes instituições: Money Plus
                SCMEPP LTDA (“BMP Money Plus”), insituição financeira, com sede
                na Avenida Paulista, nº 1765, 1º andar, CEP 01311-200, São
                Paulo/SP, inscrita no CNPJ sob o nº 11.581.339/0001-45
                (Ouvidoria da Instituição Financeira: 0800 601 52 89) e do BANCO
                TOPÁZIO S.A., banco múltiplo, inscrito no CNPJ sob nº
                07.679.404/0001-00, com sede na Rua Dezoito de Novembro, nº 273,
                conjunto 801, navegantes, Porto Alegre/RS, CEP 90.240-040, cuja
                ouvidoria atende de segunda à sexta, das 9:00 à 12:00 e das
                14:00 às 17:30 pelos telefones 4002-4466, para Capitais e
                Regiões Metropolitanas e 0800 642 8282, para outras localidades.
                As rentabilidades informadas são apenas referências e podem não
                se concretizar.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="row mb-2">
              <div className="col">
                <Link to="/ofertas-encerradas">
                  Lista de Ofertas Encerradas 588CVM
                </Link>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col">
                <a
                  href="https://admin.inco.vc/sys/assets/arquivos/gerenciador_pdf/9303a92cfcac8a0a90ca35d7bc64ef62.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Material Didático
                </a>
              </div>
            </div>
            <div style={{}} className="row mb-5">
              <div className="col-12 col-sm-3 col-md-2 mb-3">
                <img
                  alt="Logo Inco Investimento Coletivos"
                  width={145}
                  src={INCO}
                />
              </div>
              <div className="col">
                <p className="text-justify">
                  Essa oferta faz parte do programa Powered By INCO, captações
                  em que a INCO não faz análise de risco de crédito, apenas
                  atuando como plataforma aberta de investimentos coletivos
                  regulada pela I588 CVM.
                </p>
                <p className="text-justify">
                  Toda a diligência jurídica para a oferta pública dessa oferta
                  segue o padrão da regulação I588 CVM.
                </p>
                <p className="text-justify">
                  Leia com atenção o relatório de crédito da operação e o termos
                  de risco da oferta antes de investir.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-3 col-md-2 mb-3">
                <img width={145} src={CVM} />
              </div>
              <div style={{}} className="col">
                <p className="text-justify">
                  As sociedades empresárias de pequeno porte e as ofertas
                  apresentadas nesta plataforma estão automaticamente
                  dispensadas de registro pela Comissão de Valores Mobiliários -
                  CVM.
                </p>
                <p className="text-justify">
                  A CVM não analisa previamente as ofertas.
                </p>
                <p className="text-justify">
                  As ofertas realizadas não implicam por parte da CVM a garantia
                  da veracidade das informações prestadas, de adequação à
                  legislação vigente ou julgamento sobre a qualidade da
                  sociedade empresária de pequeno porte.
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </footer>
  );
};

export default connect(({ partner_data }) => ({ partner_data }))(Footer);
