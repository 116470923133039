/* eslint-disable */
import React, { Fragment } from "react";

export default class ContentInvestidores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const params = { name: "investidores" };
    if (!this.state.isMounted) {
      return null;
    }

    return (
      <>
        <div className={`row ${this.props.nameContainer} ${params.name}`}>
          <div className={`col-md-12 col-xs-12 textoTab texto_${params.name}`}>
            <section>
              <article style={{ padding: 0, marginTop: "30px" }}>
                <p>
                  {this.props.investimentos.length + 1} pessoas investiram até o
                  momento.
                </p>

                <ul
                  className="box-investidores"
                  style={{
                    width: "100%",
                    margin: "0",
                    padding: "0",
                    display: "flex",
                    WebkitBoxAlign: "start",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                  }}
                >
                  <li>
                    <div
                      className="wrapper"
                      style={{
                        backgroundColor: "#c3c3c3",
                        position: "relative",
                        width: "70px",
                        height: "70px",
                        borderRadius: "50%",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_URL}/assets/arquivos/empresas/897c9033d137ef08bc68f37fd826986d.jpg)`,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          backgroundRepeat: "no-repeat",
                          position: "absolute",
                          top: "0",
                          right: "0",
                          bottom: "0",
                          left: "0",
                          zIndex: "1",
                        }}
                      />
                    </div>
                  </li>
                  {this.props.investimentos.map((item, key) => {
                    if (
                      item.exibir_imagem === "S" &&
                      typeof item.foto !== "object"
                    ) {
                      return (
                        <li key={key}>
                          <div
                            className="wrapper"
                            style={{
                              backgroundColor: "#c3c3c3",
                              position: "relative",
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <div
                              className="img"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_URL}/${item.foto})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundRepeat: "no-repeat",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                                zIndex: "1",
                              }}
                            />
                          </div>
                        </li>
                      );
                    }
                    return (
                      <li>
                        <div
                          className="wrapper"
                          style={{
                            backgroundColor: "#c3c3c3",
                            position: "relative",
                            width: "70px",
                            height: "70px",
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className="name"
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              WebkitTransform: "translate(-50%,-50%)",
                              transform: "translate(-50%,-50%)",
                              color: "#022169",
                              fontSize: "16px",
                              fontWeight: "700",
                              margin: "0",
                              padding: "0",
                            }}
                          >
                            {item.nome[0]}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </article>
            </section>
          </div>
        </div>
      </>
    );
  }
}
