import styled from "styled-components";

export const Card = styled.div`
  margin-top: 60px;
`;

export const Resume = styled.div`
  display: flex;
  flex-flow: column;
  .progress-bar {
    background-color: ${(props) =>
      props.theme.cor_primaria || "#27cc4a"} !important;
  }
  .values h4 {
    color: ${(props) => props.theme.cor_primaria || "#27cc4a"} !important;
  }

  .tabs {
    height: 40px;
    display: flex;
    margin: 0px -15px;
    .head-tab {
      overflow: hidden;
      outline: none;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 8px;
      border: 2px solid #dfe1e0;
      border-bottom: none;
      border-radius: 0px 12px 0px 0px !important;
      cursor: pointer;
      b {
        pointer-events: none;
      }
    }
    .tab-active {
      border-color: ${(props) => props.theme.cor_primaria || "#27cc4a"};
      background-color: ${(props) => props.theme.cor_primaria || "#27cc4a"};
      color: #fff;
    }
  }
  .tab-body {
    padding-top: 40px;
    border: 2px solid ${(props) => props.theme.cor_primaria || "#27cc4a"};
    margin: 0px -15px;
    padding: 40px 15px 30px 15px;
  }

  .infos {
    .rentabilidade,
    .prazo,
    .tipo,
    .minimo {
      display: flex;
      flex-flow: column;
      b {
        font-size: 1rem;
      }
      span {
        font-size: 0.8rem;
        margin-top: 2px;
      }
    }
    div + div {
      margin-top: 16px;
    }
  }

  .extra {
    display: flex;
    flex-flow: column nowrap;
    padding: 8px 0px;
    justify-content: space-between;

    .tempo-restante {
      display: flex;
      flex: 1;
      align-items: center;
      font-size: 0.8rem;
      margin-bottom: 16px;
      svg {
        font-size: 20px;
        margin-right: 8px;
      }
      p {
        margin: 0px !important;
      }
    }
    .prospecto {
      display: flex;
      flex: 1;
      align-items: center;
      margin-bottom: 16px;
      a {
        font-size: 0.8rem;
        color: ${(props) => props.theme.cor_primaria || "#27cc4a"};
      }
      svg {
        color: ${(props) => props.theme.cor_primaria || "#27cc4a"};
        font-size: 20px;
        margin-right: 8px;
      }
    }

    .disclaimer {
      margin: 16px 0px;
    }
  }
  .cta {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      padding-top: 24px;
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 290px;
      height: 64px;
      border-radius: 30px !important;
      font-size: 1.2rem;
      border-color: ${(props) => props.theme.cor_primaria || "#27cc4a"};
      background-color: ${(props) => props.theme.cor_primaria || "#27cc4a"};
    }
  }
`;

export const Image = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1;
  padding-left: 0px;
  .head {
    display: flex;
    flex-flow: column;
    height: 80px;
    @media (max-width: 768px) {
      padding: 0px 15px;
    }
    .title {
      display: flex;
      flex-flow: row nowrap;
    }
    img {
      height: 28px;
      margin-left: 16px;
    }
    .question {
      font-family: "CamptonBold";
      text-decoration: underline;
      margin: 0px 8px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .image {
    width: 100%;
  }
  .share {
    padding-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    color: #bbb;
    align-items: flex-end;
    @media (max-width: 768px) {
      padding: 8px 15px;
    }
    ul {
      a {
        color: #bbb;
      }
      display: flex;
      flex-flow: row nowrap;
      list-style: none;
      margin-left: 12px;
      margin-bottom: 0px;
      font-size: 24px;
      @media (max-width: 767px) {
        margin-bottom: 16px;
      }
      a + a {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0px;
  }
`;
