/* eslint-disable */
import React, { Fragment } from "react";
import {BASE_URL} from '../../../helper/config';
import CardDownloadAnexo from "../cardDownloadAnexo";
import { post } from "../../../helper/request.helper";

function responder_mensagem(enc_id, obj) {
  alert();
  return false;
}

export default class ContentForum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  getForum() {
    post(`${BASE_URL}/callback/forum_new`, {
      id: this.props.id,
    }).then((response) => {
      const containerForum = document.getElementById("dados_forum");
      containerForum.innerHTML = response.data.forum;
    });
  }

  componentDidMount() {
    this.getForum();

    this.setState({ isMounted: true });
  }

  render() {
    const params = { name: "juridico" };
    if (!this.state.isMounted) {
      return null;
    }

    return (
      <>
        <div className={`row ${this.props.nameContainer} ${params.name}`}>
          <div className={`col-md-12 col-xs-12 textoTab texto_${params.name}`}>
            <section>
              <article style={{ padding: 0, marginTop: "30px" }}>
                <div id="dados_forum" className="wrapper-forum">
                  {/* Carrega via ajax */}
                </div>
              </article>
            </section>
          </div>
        </div>
      </>
    );
  }
}
