/* eslint-disable */
import Compress from 'compress.js';


export default async function compressFile(file) {
  const compress = new Compress();
  return (compress.compress([file], {
    size: 4, // the max size in MB, defaults to 2MB
    quality: 0.75, // the quality of the image, max is 1,
    maxWidth: 1920, // the max width of the output image, defaults to 1920px
    maxHeight: 1920, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  }).then((result) => result));
}
