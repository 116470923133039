/* eslint-disable */
import React, { Fragment } from "react";
import CardDownloadAnexo from "../cardDownloadAnexo";

export default class ContentJuridico extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }
  render() {
    let params = { name: "juridico" };
    if (!this.state.isMounted) {
      return null;
    }

    return (
      <Fragment>
        <div className={"row " + this.props.nameContainer + " " + params.name}>
          <div className={"col-md-12 col-xs-12 textoTab texto_" + params.name}>
            <section>
              <article style={{ padding: 0, marginTop: "30px" }}>
                <div
                  className="description"
                  style={{ lineHeight: "21px" }}
                  dangerouslySetInnerHTML={{ __html: this.props.texto }}
                ></div>

                <CardDownloadAnexo
                  href={`${process.env.REACT_APP_URL}/assets/arquivos/empreendimentos/juridico`}
                  data={this.props.juridico}
                />
              </article>
            </section>
          </div>
        </div>
      </Fragment>
    );
  }
}
