import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";
import Clicksign from "../../lib/clicksign";
import { private_postApi } from "../../helper/request.helper";

const CVM = ({ pendency, submitAssign }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (mounted) {
    // Carrega o widget embedded com a request_signature_key
    const widget = new Clicksign(pendency.request_signature_key);

    // Define o endpoint https://sandbox.clicksign.com ou https://app.clicksign.com
    widget.endpoint = "https://app.clicksign.com";

    // Define a URL de origem (parametro necessário para utilizar através de WebView)
    widget.origin = `https://${window.location.host}`;

    // Monta o widget no div
    widget.mount("contrato_cvm");

    // Callback que será disparado quando o documento for assinado
    widget.on("signed", async () => {
      await private_postApi("/investir/update_assinatura", { i: pendency.id });
      toast.success("Contrato assinado com sucesso!");
      submitAssign();
      widget.unmount();
    });
  }

  return (
    <>
      <ToastContainer autoclose={8000} />
      <div id="contrato_cvm" />
    </>
  );
};

export default CVM;
