import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import OAV from "../../components/contrato_oav";
import CVM from "../../components/contrato_cvm";

const Contrato = ({ project, investment, dispatch, match }) => (
  <div className="container">
    <div className="row">
      <div className="col-10">
        <h3>Assinatura do contrato.</h3>
        <span>Nessa etapa você garante sua reserva de investimento.</span>
      </div>
      <div className="col-2 d-flex justify-content-end">
        <Link className="btn" to={`/projeto/${match.params.project}`}>
          <AiOutlineClose size="24px" />
        </Link>
      </div>
      <div className="col-12">
        <div className="col-12 col-md-10 mt-3 px-0 mx-auto">
          {project.dados.canal_assinatura === "moneyp" ? <OAV /> : <CVM />}
        </div>
      </div>
    </div>
  </div>
);

export default connect(({ project, investment }) => ({ project, investment }))(
  Contrato
);
