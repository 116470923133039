import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  input[type=checkbox] {
      -webkit-transform: scale(1.3,1.3);
  }
  .btn-success {
    background-color: ${(props) =>
      props.theme.cor_primaria || "#27cc4a"} !important;
    border-color: ${(props) =>
      props.theme.cor_primaria || "#27cc4a"} !important;
    color: ${(props) => props.theme.cor_secundaria || "#fff"};

    &:hover, &:active, &:focus {
    background-color: ${(props) =>
      props.theme.cor_secundaria || "#27cc4a"} !important;
    border-color: ${(props) =>
      props.theme.cor_primaria || "#27cc4a"} !important;
    color: ${(props) => props.theme.cor_primaria || "#fff"};
    }

   
  }
  .btn-outline-success {
    border-color: ${(props) =>
      props.theme.cor_primaria || "#27cc4a"} !important;
    color: ${(props) => props.theme.cor_primaria || "#27cc4a"};

    &:hover, &:active, &:focus {
      background-color: ${(props) =>
        props.theme.cor_primaria || "#27cc4a"} !important;
    color: ${(props) => props.theme.cor_secundaria || "#fff"};
    }
  }
  button {
    cursor: pointer;
  }

  a.dashboard-nav-links {
    &:hover {
      background: ${(props) => props.theme.cor_primaria || '#27cc4a'} !important;
    }

    &.active {
      background: ${(props) => props.theme.cor_primaria || '#27cc4a'} !important;
    }
  }
`;
