/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { private_getApi } from "../../helper/request.helper";
import Nav from "../../components/footerNav";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
const Boleto = ({ investment, match }) => {
  const [boleto, setBoleto] = useState(false);
  const handleBoleto = async () => {
    if (typeof boleto !== "string") {
      setBoleto(true);
      const d = new Date();
      return private_getApi(
        `/clientes/gerar_boleto/${
          investment.contract ? investment.contract.id : investment.id
        }/${d.getTime()}`
      ).then(async (response) => {
        if (response.data.success) {
          toast.success("Boleto gerado com sucesso!");
          return setBoleto(response.data.link_boleto);
        }
        return toast.error("Erro ao gerar boleto");
      });
    }
    if (typeof boleto === "string") {
      return window.open(boleto, "_blank");
    }
    return false;
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-10">
          <h3>Pagamento</h3>
          <p>
            Este é o último passo! Nesta etapa você realizará o pagamento
            referente ao seu investimento.
          </p>
          <hr />
          <p>
            Você também poderá concluir essa etapa pelo painel de investidor.
          </p>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <Link className="btn" to={`/projeto/${match.params.project}`}>
            <AiOutlineClose size="24px" />
          </Link>
        </div>
        <div className="col-12"></div>
      </div>
      <div className="col">
        <div className="boleto-pendencia py-3">
          <button
            disabled={boleto && typeof boleto !== "string"}
            type="button"
            onClick={handleBoleto}
            className={`btn btn-${!boleto ? "outline-" : ""}success`}
          >
            {boleto
              ? typeof boleto !== "string"
                ? "Gerando..."
                : "Abrir boleto"
              : "Gerar boleto"}
          </button>
        </div>
      </div>
      <div className="col d-flex align-items-end justify-content-end">
        <Nav />
      </div>
    </div>
  );
};

export default connect(({ investment }) => ({ investment }))(Boleto);
