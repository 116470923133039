import React, { useEffect } from "react";
import "./style.css";
import { moeda } from "../../helper/numbers.helper";

const Barra = (props) => {
  const { alvo, captado, limite } = props.data;

  const porcent_alvo =
    (parseFloat(captado === null ? 0 : captado) / parseFloat(alvo)) * 100;

  const porcent_limite =
    (parseFloat(captado === null ? 0 : captado) / parseFloat(limite)) * 100;
  return (
    <div className="container-bar d-flex flex-column">
      <div className="bar">
        {porcent_alvo < 100 ? (
          <div className="total-bar">
            <div
              style={{ width: `${porcent_alvo}%` }}
              className="progress-bar"
            ></div>
          </div>
        ) : (
          <div className="bars">
            <div className="progress-bar"></div>
            <div className="ballon"> Cotas extras </div>
            <div className="divider-bar" />
            <div className="total-bar">
              <div
                style={{ width: `${porcent_limite}%` }}
                className="progress-bar"
              ></div>
            </div>
          </div>
        )}
      </div>
      <div className="values d-flex flex-column">
        <h4>{moeda(captado)}</h4>
        <div className="d-flex">
          <b>{parseInt(captado / 100)} vidas impactadas.</b>
        </div>
      </div>
      <hr />
    </div>
  );
};

Barra.defaultProps = {
  data: {
    alvo: 0,
    captado: 0,
    limite: 0,
  },
};

export default Barra;
