/* eslint-disable */
import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import { isAfter } from "date-fns";

import { moeda } from "../../../helper/numbers.helper";

import "./contentPagamento.css";
import "./tab-content.css";

const date_formated = (data = "01/01/2020") => {
  if (data !== null) {
    const date = data.split(" ");
    const newDate = date[0].split("-");
    const meses = [
      "jan",
      "fev",
      "mar",
      "abr",
      "mai",
      "jun",
      "jul",
      "ago",
      "set",
      "out",
      "nov",
      "dez",
    ];
    const grudDate = `${newDate[2]} ${meses[parseInt(newDate[1]) - 1]} ${
      newDate[0]
    }`;
    return grudDate;
  }
};

const columns = [
  {
    name: "Parcela",
    selector: "parcela",
    sortable: true,
    maxWidth: "10px",
  },
  {
    name: "Valor bruto",
    selector: "valor_bruto",
    sortable: true,
    cell: (row) => moeda(row.valor_bruto),
  },
  {
    name: "I.R",
    selector: "imposto_renda",
    sortable: true,
    cell: (row) => moeda(row.imposto_renda),
  },
  {
    name: "Parcela líquida",
    selector: "valor_parcela",
    sortable: true,
    cell: (row) => moeda(row.valor_parcela),
  },
  {
    name: "Data",
    selector: "data_pagamento",
    sortable: true,
    cell: (row) => date_formated(row.data_pagamento),
  },
];

const columnsIPCA = [
  {
    name: "Mês",
    selector: "mes_referencia",
    sortable: true,
    maxWidth: "30px",
    cell: (row) => row.mes_referencia.charAt(0).toUpperCase() + row.mes_referencia.slice(1)
  },
  {
    name: "IPCA Mês",
    selector: "ipca_mes",
    sortable: true,
    cell: (row) => `${row.ipca_mes}`,
  },
  {
    name: "Período em dias",
    sortable: true,
    cell: (row) => `${row.periodo_corrido}`,
  },
  {
    name: "IPCA período",
    selector: "ipca_periodo",
    sortable: true,
    cell: (row) => `${row.ipca_periodo}`,
  },
  {
    name: "Juros período",
    selector: "juros_periodo",
    sortable: true,
    cell: (row) => `${row.juros_periodo}`,
  },
]

const customStyles = {
  rows: {
    style: {
      marginBottom: "24px",
    },
  },
};

export default class ContentPagamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    return (
      <div className="table-pagamentos">
        <h3>Saldos</h3>
        <p>Os juros pós-fixados são calculados no final do período de apuração. Em casos mensais, todo fim de mês. A plataforma revela essa apuração no dia 15 do mês seguinte.</p>

        <DataTable
          responsive
          noDataComponent={
            this.props.dados.dados.pagamento_mensal !== "3" ? (
              <span style={{ color: "rgba(0,0,0,.38", marginBottom: "30px" }}>
                Nenhum pagamento foi recebido até o momento
              </span>
            ) : (
              ""
            )
          }
          columns={columnsIPCA}
          width="100%"
          progressComponent={
            <div
              className="itemLoading"
              style={{ width: "100%", padding: "15px" }}
            >
              Carregando...
            </div>
          }
          keyField="id"
          data={
            this.props.dados.fluxo_rendimento_ipca.sort((a,b) => {
              const months = [
                'janeiro',
                'fevereiro',
                'marco',
                'abril',
                'maio',
                'junho',
                'julho',
                'agosto',
                'setembro',
                'outubro',
                'novembro',
                'dezembro'
              ]
              const aYear = a['mes_consulta'].split('-')[0]
              const aMonth = months.indexOf(a['mes_referencia'])
              const bYear = b['mes_consulta'].split('-')[0]
              const bMonth = months.indexOf(b['mes_referencia'])

              if (isAfter(new Date(aYear, aMonth), new Date(bYear, bMonth))) {
                return 1

              } else {
                return -1

              }
            })
          }
          customStyles={customStyles}
        />

        <h3 className="title" style={{ marginBottom: "10px" }}>
          Pagamentos recebidos
        </h3>
        <DataTable
          responsive
          noDataComponent={
            this.props.dados.dados.pagamento_mensal !== "3" ? (
              <span style={{ color: "rgba(0,0,0,.38", marginBottom: "30px" }}>
                Nenhum pagamento foi recebido até o momento
              </span>
            ) : (
              ""
            )
          }
          columns={columns}
          width="100%"
          progressComponent={
            <div
              className="itemLoading"
              style={{ width: "100%", padding: "15px" }}
            >
              Carregando...
            </div>
          }
          keyField="id"
          data={this.props.dados.investimento.pagamentos.parcelas_pagas}
          customStyles={customStyles}
        />
      </div>
    );
  }
}
