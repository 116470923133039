import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { private_getApi } from "../../helper/request.helper";

import Bullet from "./acompanhe/bullet";
import Price from "./acompanhe/price";
import Pool from "./acompanhe/pool";
import JurosMensais from "./acompanhe/juros_mensais";

export default function Acompanhe(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      return private_getApi(
        `/clientes/acompanhe/${props.match.params.project}`
      ).then(async (response) => {
        if (response.data.success) {
          return setData(response.data.data);
        }
        return toast.error("Ocorreu um erro ao carregar a página");
      });
    };

    getData();
  }, []);

  const renderLayout = (tipo) => {
    switch (parseInt(tipo)) {
      case 0:
        return <Bullet data={data} />;
      case 1:
        return <JurosMensais data={data} />;
      case 2:
        return <Price data={data} />;
      case 3:
        return <Pool data={data} />;
      default:
        return <Bullet data={data} />;
    }
  };

  return (
    <>
      {data ? (
        <Helmet>
          <title>{`Acompanhe: ${data.dados.nome}`}</title>
        </Helmet>
      ) : (
        ""
      )}
      <ToastContainer autoClose={false} />
      {data ? (
        renderLayout(data.dados.pagamento_mensal)
      ) : (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Carregando...</span>
          </div>
        </div>
      )}
    </>
  );
}
